import './Detail_heder.scss';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { orange } from '@mui/material/colors';
import { Box, Dialog, Grid, IconButton, Typography } from '@mui/material';
import { Button, Form, InputGroup } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { dashboardActions } from "../../../../../../../_actions";
import { useNavigate } from "react-router-dom";
import { getUser } from '../../../../../../../_helpers';
import { paymentJourneyActions } from '../../../../../../../_actions/paymentJourney.actions';
import { useSearchParams } from "react-router-dom";
import styles from '../../../Create_your_invoice/components/Preview_fils/Preview_fils.module.scss';
import { Menu, MenuItem } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MarkAsPaidModal from "../markAsModal";


const Detail_heder = (props: any) => {
    const theme = createTheme({
        status: {
            danger: orange[500],
        },
    });
    let navigate = useNavigate();
    const userD = getUser()?.data ?? null;
    const dispatch = useDispatch();
    const [openShare, setOpenShare] = useState(false);
    const [idPage, setIdPage] = useState("")
    const [searchParams, setSearchParams] = useSearchParams();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const openMenu = Boolean(anchorEl);
    const [showModal, setShowModal] = useState(false);
    const handleClose = () => setShowModal(false);



    useEffect(() => {
        const popup: any = searchParams.get('id');
        setIdPage(popup)
    }, [searchParams])

    useEffect(() => {
        if (props?.id) {
            dispatch(dashboardActions.getInvoicedetels(props?.id) as any);
            //   setTimeout(() => (
            //     setRedusar(true)
            //   ), 3000)
        }
    }, [props.id])
    const Redayrecat = () => {
        navigate("/balance?popup=" + props?.id, { replace: true, state: { popup: props?.id } })
    }
    const handleClickOpenShare = () => {
        setOpenShare(true);
    };
    const handleCloseShare = () => {
        setOpenShare(false);
    };
    const getInvoiceUpdate = (data: any) => {
        dispatch(paymentJourneyActions.UpdateStatusInvoice(idPage, data,"") as any);
    }
    const ReceiverApi=()=>{
        
        dispatch(paymentJourneyActions.invoicesendreceiverV2(props?.data?.data,idPage) as any);
    }

    console.log("props detials =>", props);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null); 
  };

  const handleSendViaEmail = () => {
    navigate("/ComposeEmail?invoiceId=" + idPage)
  }

  const handleMarkAsPaid = () => {
        setShowModal(true)
  }
    
    
    return (
        <>
            <ThemeProvider theme={theme}>
                <Dialog
                    fullWidth={true}
                    maxWidth={'sm'}
                    open={openShare}
                    onClose={handleCloseShare}
                >
                    <div className={styles.contenar_divdialog}>
                        <div className={styles.Share_Invoice}>
                            <Typography>
                                Share Invoice{' '}
                                with Receiver
                            </Typography>
                            <IconButton
                                onClick={() => {
                                    handleCloseShare();
                                }}
                            >
                                <Close />
                            </IconButton>
                        </div>
                        <div className={styles.div_buttomborder}></div>
                        <Typography className={styles.text_Typography}>
                            Share invoice via
                        </Typography>
                        <div className={styles.img_and_inputcls}>
                            <div id={styles.div_classname} className="input-form-wrapper">
                                <label>
                                    Receiver's email
                                </label>
                                <InputGroup className="doc-input-wrapper">
                                    <Form.Control
                                        disabled
                                        className={'doc-form-input test valid'}
                                        placeholder="Invoice name"
                                        value={
                                            props?.data?.data.customer?.contact_email
                                        }
                                        name="name"
                                        type="text"
                                    />
                                </InputGroup>
                            </div>
                        </div>
                        <div>
                            <Button
                                className={styles.two_button_invoice_data}
                                onClick={ReceiverApi}
                            >
                                Share
                            </Button>
                        </div>
                    </div>
                </Dialog>
                <Grid container className='container_Detail_heder'>
                    <Grid item md={6} sm={12} xs={12}>
                        <Box className='container_Detail_heder_box'>
                            <IconButton className="close_button"
                                onClick={() => { navigate("/invoice") }}>
                                <Close /></IconButton>

                            <Typography className='heding_tagemain'>{props?.data?.data?.customer?.name}</Typography>
                            <Typography
                                className="canceled_typogarafi"
                            >
                                {props?.data?.data?.status == "approve_in_progress" ? "Pending" :
                                    props?.data?.data?.status == "new" ? "New" :
                                        props?.data?.data?.status == "draft" ? "Draft" :
                                            props?.data?.data?.status == "rejected" ? "Rejected" :
                                                props?.data?.data?.status == "paid" ? "Paid" :
                                                    props?.data?.data?.status == "canceled" ? "Canceled" :
                                                        props?.data?.data?.status == "waiting_to_be_paid" ? "Waiting_to_be_paid" :
                                                            props?.data?.data?.status}</Typography>
                        </Box>
                    </Grid>
                    <Grid item md={6} sm={12} xs={12}>
                        <Box className='box_main_teobutton'>
                            {props?.data?.data?.status == "draft" ?
                                <>
                                    <Button className='button_Sharereceiver'
                                        onClick={() => {
                                            navigate("/create-invoice?id=" + props?.data?.data?.id, { replace: true, state: { id: props?.data?.data?.id } })
                                        }}
                                    >Edit Invoice</Button>
                                    {/* <Button className='Approve_button' onClick={() => { getInvoiceUpdate("new") }}>Send</Button> */}
                                    <Button className='Approve_button'
                                    id="dropdown-button"
        aria-controls={openMenu ? 'dropdown-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={openMenu ? 'true' : undefined}
        onClick={handleClick}
        variant="contained"
                                    >Send Invoice
            <KeyboardArrowDownIcon />
                                    </Button>
                                     <Menu
        id="dropdown-menu"
        anchorEl={anchorEl}
        open={openMenu}
        onClose={handleCloseMenu}
        MenuListProps={{
          'aria-labelledby': 'dropdown-button',
        }}
      >
        <MenuItem 
        style={{ fontSize: "13px" }}
        onClick={() => {
          handleSendViaEmail();
            }}>
            Send via Email</MenuItem>
        <MenuItem onClick={handleCloseMenu} style={{ fontSize: "13px" }}>Download PDF</MenuItem>
      </Menu>
                                </> 
                                
                                :
                                    props?.data?.data?.status == "issued" ?
                                        <>
                                        <Button className='button_Sharereceiver'
                                        onClick={() => {
                                            navigate("/invoice", { replace: true })
                                        }}
                                    >   Cancel</Button>
                                            <Button className='Approve_button' onClick={() => { 
                                                handleMarkAsPaid()
                                                }}>Mark as paid</Button>
                                                <Button className='Approve_button'
                                    id="dropdown-button"
        aria-controls={openMenu ? 'dropdown-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={openMenu ? 'true' : undefined}
        onClick={handleClick}
        variant="contained"
                                    >Send Invoice
            <KeyboardArrowDownIcon />
                                    </Button>
                                     <Menu
        id="dropdown-menu"
        anchorEl={anchorEl}
        open={openMenu}
        onClose={handleCloseMenu}
        MenuListProps={{
          'aria-labelledby': 'dropdown-button',
        }}
      >
        <MenuItem 
        style={{ fontSize: "13px" }}
        onClick={() => {
          handleSendViaEmail();
            }}>
            Send via Email</MenuItem>
        <MenuItem onClick={handleCloseMenu} style={{ fontSize: "13px" }}>Download PDF</MenuItem>
      </Menu>
                                        </> 
                                        
                                        :
                                        props?.data?.data?.status == "paid" ?
                                        <>
                                         <Button className='Approve_button'
                                    id="dropdown-button"
        aria-controls={openMenu ? 'dropdown-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={openMenu ? 'true' : undefined}
        onClick={handleClick}
        variant="contained"
                                    >Send Invoice
            <KeyboardArrowDownIcon />
                                    </Button>
                                     <Menu
        id="dropdown-menu"
        anchorEl={anchorEl}
        open={openMenu}
        onClose={handleCloseMenu}
        MenuListProps={{
          'aria-labelledby': 'dropdown-button',
        }}
      >
        <MenuItem 
        style={{ fontSize: "13px" }}
        onClick={() => {
            handleSendViaEmail();
            }}>
            Send via Email</MenuItem>
        <MenuItem onClick={handleCloseMenu} style={{ fontSize: "13px" }}>Download PDF</MenuItem>
      </Menu>
                                        </>
                                        :
                                         props?.data?.data?.status == "partially_paid" ?
                                         <>
                                         <Button className='Approve_button' onClick={() => { 
                                                handleMarkAsPaid()
                                                }}>Mark as paid</Button>
                                          <Button className='Approve_button'
                                    id="dropdown-button"
        aria-controls={openMenu ? 'dropdown-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={openMenu ? 'true' : undefined}
        onClick={handleClick}
        variant="contained"
                                    >Send Invoice
            <KeyboardArrowDownIcon />
                                    </Button>
                                     <Menu
        id="dropdown-menu"
        anchorEl={anchorEl}
        open={openMenu}
        onClose={handleCloseMenu}
        MenuListProps={{
          'aria-labelledby': 'dropdown-button',
        }}
      >
        <MenuItem 
        style={{ fontSize: "13px" }}
        onClick={() => {
              handleSendViaEmail();
            }}>
            Send via Email</MenuItem>
        <MenuItem onClick={handleCloseMenu} style={{ fontSize: "13px" }}>Download PDF</MenuItem>
      </Menu>
                                         </>
                                         :
                                        ""}
                        </Box>
                    </Grid>
                </Grid>




                {/* mark as modal */}
      {showModal && (
        <MarkAsPaidModal handleClose={handleClose} showModal={showModal} />
      )}
            </ThemeProvider>
        </>
    )
}
export default Detail_heder;
const Close = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
        <path d="M0.757812 11.2428L6.00081 5.99984L11.2438 11.2428M11.2438 0.756836L5.99981 5.99984L0.757812 0.756836" stroke="#1D1D1D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
)