import { Box, Grid, IconButton, Typography } from '@mui/material';
import styles from './connectInvoiceTable.module.scss';
import React, { useEffect, useState } from 'react';
import { Button, Form, InputGroup, Table } from 'react-bootstrap';
import { isUserAuthenticated, authHeader } from '../../../../../../../../_helpers';
import axios from 'axios';
import moment from 'moment';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { orange } from '@mui/material/colors';
import DatePickerll from 'react-datepicker';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TableHead from '@mui/material/TableHead';
import Checkbox from '@mui/material/Checkbox';
import TableBody from '@mui/material/TableBody';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Menu, MenuItem, } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useDispatch, useSelector } from "react-redux";
import { counterpartsActions } from '../../../../../../../../_actions/counterparts.actions'
import { selectAllCounterpartsListData } from '../../../../../../../../_reducers/counterparts.reducer';
import { TebalNoData } from '../../../../../Skeleton/TebalNoData';
import { ListSkeleton } from '../../../../../Skeleton/Skeleton';

interface HeadCell {
    disablePadding: boolean;
    id: any;
    label: string;
    numeric: boolean;
}

const headCells: readonly HeadCell[] = [
    {
        id: 'Date',
        numeric: false,
        disablePadding: true,
        label: 'Date',
    },
    {
        id: 'Payment Reference',
        numeric: true,
        disablePadding: false,
        label: 'Payment Reference',
    },
    {
        id: 'Category',
        numeric: true,
        disablePadding: false,
        label: 'Category',
    },
    {
        id: 'Description',
        numeric: true,
        disablePadding: false,
        label: 'Description',
    },

    {
        id: 'Transaction',
        numeric: true,
        disablePadding: false,
        label: 'Transaction',
    },
    {
        id: 'Reconciled Amount',
        numeric: true,
        disablePadding: false,
        label: 'Reconciled Amount',
    },
];

const ConnectInvoiceTable = () => {
    var localUser: any = localStorage.getItem("user");
    const [search, setSearch] = React.useState('');
    const [categ, setCateg] = React.useState('');
    const [loderinapi, setLoderapi] = React.useState(false);
    const [datatabal, setDatatebal] = React.useState<any>([
    ]);
    const [selectData, setSelecetData] = useState([])
    const [statsData, setStatsData] = useState([
        { name: "All", APIdata: "all" },
        { name: "Partialy paid", APIdata: "Partialy_paid" },
    ])
    const [totalCount, setTotalCount] = React.useState(10);
    const [tagsList, setTagsList] = React.useState<any>([]);
    const [startDate, setStartDate] = React.useState();
    const [endDate, setEndDate] = React.useState();
    const [pageneshn, setPagelist] = React.useState(1);
    const [remove, setRemovedata] = React.useState(10);
    const [dense, setDense] = React.useState(false);
    const [selected, setSelected] = React.useState<any>([]);
    const [rowsPerPage, setRowsPerPage] = React.useState(100);
    const [page, setPage] = React.useState(0);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [delete_data, setDelete_data] = React.useState('')
    const [ideditdata, setIdeditdata] = React.useState('')
    const [Redusar, setRedusar] = useState(true)
    const [loading, setLoading] = useState(false)
    const [ListdoupDoun, setListdoupDoun] = useState(
        [
        { name: "All", APIdata: "all" },
        { name: "M-Pesa Paybill", APIdata: "M-Pesa Paybill" },
        { name: "M-Pesa Till", APIdata: "M-Pesa Till" },
        { name: "Pesalink", APIdata: "Pesalink" },
        { name: "M-Pesa Send Money", APIdata: "M-Pesa Send Money" },
        { name: "M-Pesa", APIdata: "M-Pesa" },
    ]
    )
    const [listRender, setListRender] = useState(false)
    const[customer_name,setCustomer_name]=useState("")
    const [bulkDeleteData, setBulkDeleteData] = React.useState<readonly string[]>([]);
    const open = Boolean(anchorEl);

    const today = new Date();
    // const navigate = useNavigate();
    const dispatch = useDispatch();

    // const counterpartsListData = useSelector((state) =>
    //     selectAllCounterpartsListData(state)
    // );
    // const handleClick_datat = (event: React.MouseEvent<HTMLButtonElement>) => {
    //     setAnchorEl(event.currentTarget);
    // };

    // const handleClose = () => {
    //     setAnchorEl(null);
    //     setDelete_data("")
    //     setIdeditdata("")
    // };
    const getBalanceList = async (search: any, status: any,customer_name:any,stateDate: any, endDate: any, pageneshn: any) => {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', ...authHeader() },
        };
        const stateDateApi = !!stateDate
            ? moment(stateDate).format('YYYY-MM-DD')
            : '';
        const endDateApi = !!endDate
            ? moment(endDate).format('YYYY-MM-DD')
            : '';
        const category = !!categ ? categ : '';

        var pagelimit = 10;
        setLoderapi(true);
        const response = await axios
            .get(
                `${process.env.REACT_APP_API_URL}${'/invoice/v2/get-list'}?search=${search
                }&status=${status}&customer_name=${customer_name}&due_date=${endDateApi}&page=${pageneshn
                }&limit=${pagelimit}`,
                requestOptions
            )
            .then((response) => {
                if (response.data.success == true) {
                    setDatatebal(response.data.data.data);
                    setTotalCount(response.data.data.total);
                    const newSelected: any = []

                    for (let index = 0; index < response.data.data.data.length; index++) {
                        const element = response.data.data.data[index];
                        if (element.status == "paid") {

                        } else {
                            newSelected.push(element.id)
                        }
                    }
                    setSelecetData(newSelected)
                    setTimeout(() => {
                        setLoderapi(false);
                    }, 2000);
                } else {
                    setTimeout(() => {
                        setLoderapi(false);
                    }, 6000);
                }
            });
    };
    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        console.log(event.target.checked, "eventevent123");

        if (event.target.checked) {
            const newSelected: any = []
            for (let index = 0; index < datatabal.length; index++) {
                const element = datatabal[index];
                if (element.status == "paid") {

                } else {
                    newSelected.push(element.id)
                }
            }
            setSelected(newSelected);
            return;
        } else {
            setSelected([]);
        }

    };

const handleClick = (moniteId: any, name: string) => {

    const selectedIndex = selected.indexOf(name);
    let newSelected: readonly string[] = [];
    let newBulkDelete: readonly string[] = [];

    if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
            selected.slice(0, selectedIndex),
            selected.slice(selectedIndex + 1)
        );
    }

    const bulkDeleteIndex = bulkDeleteData.indexOf(moniteId);

    if (bulkDeleteIndex === -1) {
        newBulkDelete = newBulkDelete.concat(bulkDeleteData, moniteId);
    } else if (bulkDeleteIndex === 0) {
        newBulkDelete = newBulkDelete.concat(bulkDeleteData.slice(1));
    } else if (bulkDeleteIndex === bulkDeleteData.length - 1) {
        newBulkDelete = newBulkDelete.concat(bulkDeleteData.slice(0, -1));
    } else if (bulkDeleteIndex > 0) {
        newBulkDelete = newBulkDelete.concat(
            bulkDeleteData.slice(0, bulkDeleteIndex),
            bulkDeleteData.slice(bulkDeleteIndex + 1)
        );
    }
    setSelected(newSelected);
    setBulkDeleteData(newBulkDelete);
};


    const isSelected = (name: string) => selected.indexOf(name) !== -1;
    const emptyRows =
        page > 0 ? Math.max(0, (1 + page) * rowsPerPage - datatabal.length) : 0;
    const theme = createTheme({
        status: {
            danger: orange[500],
        },
    });
    function doneTyping() {
        getBalanceList(search, categ,customer_name,startDate, endDate, pageneshn);
        setLoderapi(true);
    }

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            doneTyping();
            console.log('usereffect testing');
        }, 1000);
        return () => clearTimeout(timeoutId);
    }, [search]);
    useEffect(() => {
        if (isUserAuthenticated() && Redusar) {
            const stateDateApi: any = !!startDate ? moment(startDate).format('YYYY-MM-DD') : ""
            const endDateApi = !!endDate
                ? moment(endDate).format('YYYY-MM-DD')
                : '';
            getBalanceList(search, categ,customer_name,stateDateApi, endDateApi, pageneshn)
            dispatch(counterpartsActions.getCustomerDropDoun() as any);
            setTimeout(() => {
                setListRender(true)
            }, 4000)
        }
    }, [Redusar, isUserAuthenticated])
    // useEffect(() => {
    //     if (counterpartsListData?.GETCUSTOMER_DROPDOUN_SUCCESS !== undefined && listRender) {
    //         setListRender(false)
    //         setListdoupDoun(counterpartsListData?.GETCUSTOMER_DROPDOUN_SUCCESS)
    //     }
    // }, [listRender, counterpartsListData])

    // const handleShow = () => {
    //     const stateDateApi: any = !!startDate ? moment(startDate).format('YYYY-MM-DD') : ""
    //     const endDateApi = !!endDate
    //         ? moment(endDate).format('YYYY-MM-DD')
    //         : '';
    //     dispatch(counterpartsActions.Delete_Invoice(ideditdata) as any);
    //     setLoading(true)
    //     setTimeout(() => {
    //         getBalanceList(search, categ,customer_name,stateDateApi, endDateApi, pageneshn)
    //         setSelected([])
    //     }, 4000)
    // }

    // const rejectSubmit = () => {
    //     const body = {
    //         "ids": bulkDeleteData
    //     }
    //     dispatch(counterpartsActions.Delete_Balk_Invoice(body) as any);
    //     const stateDateApi: any = !!startDate ? moment(startDate).format('YYYY-MM-DD') : ""
    //     const endDateApi = !!endDate
    //         ? moment(endDate).format('YYYY-MM-DD')
    //         : '';
    //     setLoading(true)
    //     setTimeout(() => {
    //         getBalanceList(search, categ,customer_name,stateDateApi, endDateApi, pageneshn)
    //         setSelected([])
    //     }, 4000)
    // }

    console.log("ListdoupDoun =>", datatabal);
    
    return (
        <>
            <ThemeProvider theme={theme}>
             <ListSkeleton loader={loderinapi} height={'100vh'} width={'100%'}
                  element={   
                <Grid container spacing={1.9} className='grid_bill_container'>
                    <Grid item md={2.9} xs={12}>
                        <div
                            className="conteanaha_data_ketete"
                        >
                            <InputGroup className="balance__filter-wrapper input">
                                <InputGroup.Text id="basic-addon1" className="icon">
                                    <SearchIcon />
                                </InputGroup.Text>
                                <Form.Control
                                    className="balance-search-input"
                                    placeholder="Search by amount"
                                    aria-label="Search"
                                    aria-describedby="basic-addon1"
                                    disabled={loderinapi}
                                    onChange={(event) => {
                                        // console.log('testing');
                                        if (event.target.value == '') {
                                            setSearch(event.target.value);
                                        } else {
                                            setSearch(event.target.value);
                                        }
                                    }}
                                />
                            </InputGroup>
                        </div>
                    </Grid>

                    <Grid item md={1.9} xs={12}>
                        <div className=" balance_cat">
                            <Form.Select
                                onChange={(e: any) => {
                                    setCateg(e.target.value);
                                    getBalanceList(search, e.target.value,customer_name,startDate, endDate, pageneshn);
                                }}
                                value={categ}
                            >
                                <option selected value="">
                                    All Payins
                                </option>
                                {statsData?.map(
                                    (arrayElement: any, index: number) => {
                                        return (
                                            <option
                                                value={arrayElement.APIdata}
                                                key={index + 1}
                                            >
                                                {arrayElement.name}
                                            </option>
                                        );
                                    }
                                )}
                            </Form.Select>
                        </div>
                    </Grid>
                    
                    <Grid item md={1.9} xs={12}>
                        <div className=" balance_cat">
                            <Form.Select
                                onChange={(e: any) => {
                                    setCustomer_name(e.target.value);
                                    getBalanceList(search,categ,e.target.value,startDate, endDate, pageneshn);
                                }}
                                value={customer_name}
                            >
                                <option selected value="">
                                    Payment Channel
                                </option>
                                {ListdoupDoun?.map(
                                    (arrayElement: any, index: number) => {
                                        return (
                                            <option
                                                value={arrayElement.name}
                                                key={index + 1}
                                            >
                                                {arrayElement.name}
                                            </option>
                                        );
                                    }
                                )}
                            </Form.Select>
                        </div>
                    </Grid>

                    {/* <Grid item md={1.9} xs={12}>
                        <div
                            id="select_up_div"
                            className=" balance_cat"
                        >
                            <DatePickerll
                                placeholderText="Due date"
                                dateFormat="dd MMMM yyyy"
                                className='dashboard-container__datepicker'
                                selected={endDate}
                                onChange={(date: Date) => {
                                    //@ts-ignore
                                    setEndDate(date);
                                    const endDateApi = !!date
                                        ? moment(date).format('YYYY-MM-DD')
                                        : '';
                                    const stateDateApi: any = !!startDate ? moment(startDate).format('YYYY-MM-DD') : ""
                                    getBalanceList(search, categ,customer_name,stateDateApi, endDateApi, pageneshn)
                                    console.log('clicked end');
                                }}
                                selectsEnd
                            />
                        </div>

                    </Grid> */}

                    <Grid item md={12} xs={12}>
                        <Paper sx={{ width: '100%', mb: 2, marginTop: "20px", boxShadow: "0px 2px 14px 2px rgba(174, 174, 174, 0.25)" }}>
                            <TableContainer>
                                <Table
                                    className='contenar_tebal_liner'
                                    aria-labelledby="tableTitle"
                                    size={dense ? 'small' : 'medium'}
                                >
                                    <TableHead>
                                        <TableRow>
                                            <TableCell style={{ borderBottom: "0px solid" }} padding="checkbox">
                                                <Checkbox
                                                    color="primary"
                                                    icon={<img src="/Checkbox.svg" />}
                                                    checkedIcon={<img src="/Checkbox_two.svg" />}
                                                    indeterminate={selected.length > 0 && selected.length < selectData.length}
                                                    checked={selectData.length > 0 && selected.length === selectData.length}
                                                    onChange={handleSelectAllClick}
                                                    inputProps={{
                                                        'aria-label': 'select all desserts',
                                                    }}
                                                />
                                            </TableCell>
                                            {headCells.map((headCell) => (
                                                <TableCell>
                                                    {headCell.label}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>

                                        {datatabal && datatabal.length > 0 ?
                                            datatabal.map((data: any, index: any) => {
                                                const isItemSelected = isSelected(data.id);
                                                const labelId = `enhanced-table-checkbox-${index}`;
                                                // console.log(data, 'datadata', index);

                                                return (
                                                    <TableRow
                                                        role="checkbox"
                                                        aria-checked={isItemSelected}
                                                        tabIndex={-1}
                                                        key={index}
                                                        selected={isItemSelected}
                                                        sx={{ cursor: 'pointer' }}
                                                    >
                                                        <TableCell style={{ borderBottom: "0px solid" }} padding="checkbox">
                                                            <Checkbox
                                                                icon={<img src="/Checkbox.svg" />}
                                                                disabled={data.status == "paid" ? true : false}
                                                                checkedIcon={<img src="/Checkbox_two.svg" />}
                                                                onClick={(event) => handleClick(data.monite_invoice_id, data.id)}
                                                                color="primary"
                                                                checked={isItemSelected}
                                                                inputProps={{
                                                                    'aria-labelledby': labelId,
                                                                }}
                                                            />
                                                        </TableCell>
                                                        <TableCell 
                                                        // onClick={() => {
                                                        //     navigate("/invoice?id=" + data.monite_invoice_id, { replace: true, state: { id: data.monite_invoice_id } })
                                                        // }}
                                                         style={{ cursor: "pointer", borderBottom: "0px solid" }} >
                                                            {data.invoice_no}
                                                        </TableCell>
                                                        <TableCell style={{ borderBottom: "0px solid" }} 
                                                        // onClick={() => {
                                                        //     navigate("/invoice?id=" + data.monite_invoice_id, { replace: true, state: { id: data.monite_invoice_id } })
                                                        // }}
                                                        
                                                        >
                                                            {moment(data.created_at).format(
                                                                'DD-MMM-YYYY'
                                                            )}
                                                        </TableCell>
                                                        <TableCell style={{ borderBottom: "0px solid" }} 
                                                        // onClick={() => {
                                                        //     navigate("/invoice?id=" + data.monite_invoice_id, { replace: true, state: { id: data.monite_invoice_id } })
                                                        // }}
                                                        >
                                                            <Box className={styles.imgContean}>
                                                                <Typography> {data?.customer?.name}</Typography>
                                                            </Box>
                                                        </TableCell>


                                                        <TableCell style={{ borderBottom: "0px solid" }} 
                                                        // onClick={() => {
                                                        //     navigate("/invoice?id=" + data.monite_invoice_id, { replace: true, state: { id: data.monite_invoice_id } })
                                                        // }}
                                                        >
                                                            {moment(data.due_date).format(
                                                                'DD-MMM-YYYY'
                                                            )}
                                                        </TableCell>
                                                        <TableCell style={{ borderBottom: "0px solid" }} 
                                                        // onClick={() => {
                                                        //     navigate("/invoice?id=" + data.monite_invoice_id, { replace: true, state: { id: data.monite_invoice_id } })
                                                        // }}
                                                        >
                                                            {data.status == "approve_in_progress" ? "Pending" :
                                                                data.status == "new" ? "New" :
                                                                    data.status == "draft" ? "Draft" :
                                                                        data.status == "rejected" ? "Rejected" :
                                                                            data.status == "paid" ? "Paid" :
                                                                                data.status == "canceled" ? "Canceled" :
                                                                                    data.status == "waiting_to_be_paid" ? "Waiting_to_be_paid"
                                                                                        : data.status}
                                                        </TableCell>
                                                        <TableCell style={{ borderBottom: "0px solid" }} 
                                                        // onClick={() => {
                                                        //     navigate("/invoice?id=" + data.monite_invoice_id, { replace: true, state: { id: data.monite_invoice_id } })
                                                        // }}
                                                        >
                                                            KES{" "}{data.total}
                                                        </TableCell>


                                                        {/* <TableCell style={{ borderBottom: "0px solid" }} align="right">
                                                            <IconButton onClick={(e: any) => {
                                                                if (data.status != "paid") {
                                                                    setIdeditdata(data.monite_invoice_id)
                                                                }
                                                                setDelete_data(data.id)
                                                                handleClick_datat(e)
                                                            }}>
                                                                <MoreVertIcon />
                                                            </IconButton>
                                                        </TableCell> */}
                                                    </TableRow>
                                                );
                                            }) : 

                                        <TableRow>
                                             <TableCell colSpan={7} style={{ borderBottom: "0px solid" }}>
                                                      <TebalNoData text={'No Transaction found'} />    
                                             </TableCell>
                                        </TableRow>}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Paper>
                        <div style={{ paddingTop: '10px', justifyContent: "space-between" }} className="addnewbtnclass">
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <div className="inpagenum">
                                    <p>
                                        {remove - 9}-{remove}
                                    </p>
                                </div>
                                <div className="btnmaindivin">
                                    {pageneshn == 1 ? (
                                        <button disabled className="nextntmistish">
                                            <ArrowBackIosNewIcon style={{ fontSize: '16px' }} />
                                        </button>
                                    ) : (
                                        <button
                                            className="nextntmistish"
                                            onClick={() => {
                                                setPagelist(pageneshn - 1);
                                                setRemovedata(remove - 10);
                                                const endDateApi = !!endDate
                                                    ? moment(endDate).format('YYYY-MM-DD')
                                                    : '';
                                                const stateDateApi: any = !!startDate ? moment(startDate).format('YYYY-MM-DD') : ""
                                                getBalanceList(search, categ,customer_name,stateDateApi, endDateApi, pageneshn - 1)
                                            }}
                                        >
                                            <ArrowBackIosNewIcon style={{ fontSize: '16px' }} />
                                        </button>
                                    )}
                                    {remove >= totalCount ? (
                                        <button disabled className="nextntmistish">
                                            <ArrowForwardIosIcon style={{ fontSize: '16px' }} />
                                        </button>
                                    ) : (
                                        <button
                                            className="nextntmistish"
                                            onClick={() => {
                                                setPagelist(pageneshn + 1);
                                                setRemovedata(remove + 10);
                                                const endDateApi = !!endDate
                                                    ? moment(endDate).format('YYYY-MM-DD')
                                                    : '';
                                                const stateDateApi: any = !!startDate ? moment(startDate).format('YYYY-MM-DD') : ""
                                                getBalanceList(search, categ,customer_name,stateDateApi, endDateApi, pageneshn + 1)
                                            }}
                                        >
                                            <ArrowForwardIosIcon style={{ fontSize: '16px' }} />
                                        </button>
                                    )}
                                </div>
                            </div>
                            <Button
                                style={{ padding: "12px 16px" }}
                                disabled={selected.length > 0 ? false : true}
                                // onClick={() => rejectSubmit()}
                                color="#F87168"
                                className='white_btn_two'
                            >
                                Delete All
                            </Button>
                        </div>
                        {/* <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button',
                            }}
                        >
                            <MenuItem
                                disabled={delete_data == "" ? true : false}
                                onClick={() => {
                                    navigate("/invoice?id=" +
                                        delete_data, { replace: true, state: { id: delete_data } });
                                }}
                                className='menuitem'>Update</MenuItem>
                            <MenuItem
                                disabled={ideditdata == "" ? true : false}
                                onClick={() => {
                                    handleClose()
                                    handleShow()
                                }} className='menuitem' style={{ color: "#F87161" }}>Delete
                            </MenuItem>
                        </Menu> */}
                    </Grid>
                </Grid>
                     }>
             </ListSkeleton>
            </ThemeProvider>
        </>
    )
}
export default ConnectInvoiceTable;

const SearchIcon = () => (
    <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M13 13L10.0748 10.1341M11.6667 6.33334C11.6667 9.27886 9.27885 11.6667 6.33333 11.6667C3.38781 11.6667 1 9.27886 1 6.33334C1 3.38782 3.38781 1 6.33333 1C9.27885 1 11.6667 3.38782 11.6667 6.33334Z"
            stroke="#111111"
            stroke-width="1.1875"
            stroke-miterlimit="1"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
);