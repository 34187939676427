import { Avatar, Box, Radio, Typography } from '@mui/material';
import './Document_details.scss';
import React, { useEffect } from 'react';
import moment from "moment";

const Chackbox = () => (
    <svg width="18" height="17" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="6.5" cy="7" r="6" stroke="#025041" />
        <circle cx="6.5" cy="7" r="2.5" fill="#025041" />
    </svg>
)
const RadioIcon = () => (
    <svg width="18" height="17" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="6.5" cy="7" r="6" stroke="#D9D9D9" />
    </svg>
)
const Document_details = (props: any) => {
    // console.log(props?.data?.data?.customer?.name, "propsprops");
    const [selectedValue, setSelectedValue] = React.useState('b');

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedValue(event.target.value);
    };
    useEffect(() => {
        var trans_cat = [];

        if (!!props) {
            console.log(props, "propsprops");
            if (props?.data?.payable) {

            }

            // for (let index = 0; index < element.unified_transaction_categories.length; index++) {
            // const records = element.unified_transaction_categories[index];

            // }
        }
    }, [props])

    console.log("props =>", props);
    
    return (
        <>

            <div className='main_dic_contenar'>
                {/* <div className='box_div_conteanararaa'>
                    <div>
                        <Typography className='Typography_Documentdetails_text'>
                            Transfer method
                        </Typography>
                        <div className='main_div_divfooter'>
                            <div className='row_text_conteann'>
                                <Box className="box_avatarText">
                                    <Radio
                                        checked={selectedValue === 'a'}
                                        onChange={handleChange}
                                        checkedIcon={<RadioIcon />}
                                        icon={<Chackbox />}
                                        value="a"
                                        name="radio-buttons"
                                        inputProps={{ 'aria-label': 'A' }}
                                    />
                                    <Typography style={{padding:"0px"}} className='fast_text_lineen'>Send via email</Typography>
                                </Box>
                                <Typography id="conteanSelect"  className='two_text_conteanana'>{props?.data?.data?.customer?.contact_email}</Typography>
                            </div>
                            <div className='row_text_conteann'>
                                <Box className="box_avatarText">
                                    <Radio
                                        checked={selectedValue === 'b'}
                                        onChange={handleChange}
                                        checkedIcon={<RadioIcon />}
                                        icon={<Chackbox />}
                                        value="b"
                                        name="radio-buttons"
                                        inputProps={{ 'aria-label': 'B' }}
                                    />
                                    <Typography style={{padding:"0px",width:"unset"}} className='fast_text_lineen'>Download and issue manually</Typography>
                                </Box>
                                
                            </div>
                        </div>
                    </div>
                </div> */}
                <div style={{ marginTop: "6px" }} className='box_div_conteanararaa'>
                    <div>
                        <Typography className='Typography_Documentdetails_text'>
                            Quote details
                        </Typography>
                        <div className='main_div_divfooter'>
                            <div className='row_text_conteann'>
                                <Typography className='fast_text_lineen'>Customer</Typography>
                                <Box className="box_avatarText">
                                    {/* <Avatar className='avatarStyles' src='' /> */}
                                    <Typography style={{ padding: "0px" }} className='two_text_conteanana'>{props?.data?.data?.customer?.name}</Typography>
                                </Box>
                            </div>
                            <div className='row_text_conteann'>
                                <Typography className='fast_text_lineen'>Contact</Typography>
                                <Box className="box_avatarText">
                                    {/* <Avatar className='avatarStyles' src='' /> */}
                                    <Typography style={{ padding: "0px" }} className='two_text_conteanana'>{props?.data?.data?.company?.name}</Typography>
                                </Box>
                            </div>
                            <div className='row_text_conteann'>
                                <Typography className='fast_text_lineen'>Number</Typography>
                                <Typography className='two_text_conteanana'>{props?.data?.data?.invoice_no}</Typography>
                            </div>
                            <div className='row_text_conteann'>
                                <Typography className='fast_text_lineen'>Message</Typography>
                                <Typography className='two_text_conteanana'>{props?.data?.data?.memo}</Typography>
                            </div>


                            {/* <div className='row_text_conteann'>
                                <Typography className='fast_text_lineen'>Submitted by</Typography>
                                <Typography className='two_text_conteanana'>{props?.data?.payable?.submitter?.name}</Typography>
                            </div> */}
                        </div>
                    </div>
                </div>
                <div style={{ marginTop: "6px" }} className='box_div_conteanararaa'>
                    <div>
                        <Typography className='Typography_Documentdetails_text'>
                            Line items
                        </Typography>
                        <div className='main_lineitem'>
                            <div className='main_div_other_div'>
                                <div className='main_div_name' style={{ width: "26%" }}>
                                    <div className='typo_topdivv'>
                                        <Typography>Name</Typography>
                                    </div>
                                </div>
                                <div className='main_div_name' style={{ width: "20%", textAlign: "center" }}>
                                    <div className='typo_topdivv'>
                                        <Typography>Unit price</Typography>
                                    </div>
                                </div>
                                <div className='main_div_name' style={{ width: "14%", textAlign: "center" }}>
                                    <div className='typo_topdivv'>
                                        <Typography>Quantity</Typography>
                                    </div>
                                </div>
                                <div className='main_div_name' style={{ width: "20%" }}>
                                    <div className='typo_topdivv'>
                                        <Typography>Description</Typography>
                                    </div>
                                </div>

                                <div className='main_div_name' style={{ width: "20%" }}>
                                    <div className='typo_topdivv'>
                                        <Typography>Amount</Typography>
                                    </div>
                                </div>
                            </div>

                            {props?.data?.data?.items.map((data: any, index: any) => {
                                {
                                    console.log((((data.total / 100) * data.quantity) - (data.subtotal / 100)), data.total, data.subtotal, "sfszffzsffff");
                                }
                                return (
                                    <div className='main_div_other_div'>
                                        <div className='main_div_name' style={{ width: "26%" }}>
                                            <div className='typo_des_datatata'>
                                                <Typography>{data.title}</Typography>
                                            </div>
                                        </div>
                                        <div className='main_div_name' style={{ width: "20%", textAlign: "center" }}>
                                            <div className='typo_des_datatata'>
                                                <Typography>{props?.data?.data?.currency} {data.price}</Typography>
                                            </div>
                                        </div>
                                        <div className='main_div_name' style={{ width: "14%", textAlign: "center" }}>
                                            <div className='typo_des_datatata'>
                                                <Typography>{data.quantity}</Typography>
                                            </div>
                                        </div>
                                        <div className='main_div_name' style={{ width: "20%" }}>
                                            <div className='typo_des_datatata'>
                                                <Typography>{data.description}</Typography>
                                            </div>
                                        </div>

                                        <div className='main_div_name' style={{ width: "20%" }}>
                                            <div className='typo_des_datatata'>
                                                <Typography>{props?.data?.data?.currency} {data.total}</Typography>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}

                        </div>
                    </div>

                </div>
                <div style={{ marginTop: "6px" }} className='box_div_conteanararaa'>
                    <div>
                        <Typography className='Typography_Documentdetails_text'>
                            Payment details
                        </Typography>
                        <div className='main_div_divfooter'>
                            <div className='row_text_conteann'>
                                <Typography className='fast_text_lineen'>Subtotal</Typography>
                                <Typography className='threed_test_data'>{props?.data?.data?.currency} {props?.data?.data?.sub_total}</Typography>
                            </div>
                            <div className='row_text_conteann'>
                                <Typography className='fast_text_lineen'>VAT</Typography>
                                <Typography className='two_text_conteanana'> {props?.data?.payable?.vat == 'Exempt' ? 'Exempt' : `${props?.data?.data?.currency} ${props?.data?.data?.tax ? props?.data?.data?.tax : 0}`}</Typography>
                            </div>
                            <div className='row_text_conteann'>
                                <Typography className='fast_text_lineen'>Total</Typography>
                                <Typography className='two_text_conteanana'>{props?.data?.data?.currency} {props?.data?.data?.total}</Typography>
                            </div>

                        </div>
                    </div>
                </div>
                <div style={{ marginTop: "6px" }} className='box_div_conteanararaa'>
                    <div>
                        <Typography className='Typography_Documentdetails_text'>
                            Bill lifecycle
                        </Typography>

                        <div className='Bill_lifecyclediv'>
                            {props?.data?.data?.creator?.name ?
                                <>
                                    <div className='conteanBill_lifecycl'>
                                        <div className='clsTypographydiv'>
                                            <div className='Typography_icon'>
                                                <DotIcon />
                                                <Typography className='clsTypographyheding'>Created by</Typography>
                                            </div>
                                            <Typography className='clsTypographyname'>{props?.data?.data?.creator?.name}</Typography></div>
                                        <div className='Submitted_datedev'><Typography className='TypographySubmittedheding'>Creation date</Typography>
                                            <Typography className='dateTypography'>{moment(props?.data?.data?.created_at).format('YYYY-MMMM-DD')}</Typography></div>
                                    </div>
                                    {!!props?.data?.data?.submitter?.name || !!props?.data?.data?.canceller?.name || !!props?.data?.data?.rejector?.name || !!props?.data?.data?.approver?.name || !!props?.data?.data?.payer?.name ?
                                        <div className='bottum_spees' style={{ top: "29px" }}></div> : ""}
                                </>
                                : ""}
                            {props?.data?.data?.submitter?.name ?
                                <>
                                    <div className='conteanBill_lifecycl'>
                                        <div className='clsTypographydiv'>
                                            <div className='Typography_icon'>
                                                <DotIcon />
                                                <Typography className='clsTypographyheding'>
                                                    Submitted by</Typography>
                                            </div>
                                            <Typography className='clsTypographyname'>{props?.data?.data?.submitter?.name}</Typography></div>
                                        <div className='Submitted_datedev'><Typography className='TypographySubmittedheding'>Submitted date</Typography>
                                            <Typography className='dateTypography'>{moment(props?.data?.data?.submitted_at).format('YYYY-MMMM-DD')}</Typography></div>
                                    </div>
                                    {!!props?.data?.data?.canceller?.name || !!props?.data?.data?.rejector?.name || !!props?.data?.data?.approver?.name || !!props?.data?.data?.payer?.name ?
                                        <div className='bottum_spees' style={{ top: "58px" }}></div> : ""}</>
                                : ""}
                            {props?.data?.data?.canceller?.name ?
                                <>
                                    <div className='conteanBill_lifecycl'>
                                        <div className='clsTypographydiv'>
                                            <div className='Typography_icon'>
                                                <DotIcon />
                                                <Typography className='clsTypographyheding'>
                                                    Cancelled by</Typography>
                                            </div>
                                            <Typography className='clsTypographyname'>{props?.data?.payable?.canceller?.name}</Typography></div>
                                        <div className='Submitted_datedev'>
                                            <Typography className='TypographySubmittedheding'>Cancellation date</Typography>
                                            <Typography className='dateTypography'>{moment(props?.data?.payable?.cancelled_at).format('YYYY-MMMM-DD')}</Typography></div>
                                    </div>
                                    {!!props?.data?.data?.rejector?.name || !!props?.data?.data?.approver?.name || !!props?.data?.data?.payer?.name ?
                                        <div className='bottum_spees' style={{ top: "58px" }}></div> : ""}</> : ""}
                            {props?.data?.data?.rejector?.name ?
                                <>
                                    <div className='conteanBill_lifecycl'>
                                        <div className='clsTypographydiv'>
                                            <div className='Typography_icon'>
                                                <DotIcon />
                                                <Typography className='clsTypographyheding'>
                                                    Rejected by</Typography>
                                            </div>
                                            <Typography className='clsTypographyname'>{props?.data?.data?.rejector?.name}</Typography></div>
                                        <div className='Submitted_datedev'>
                                            <Typography className='TypographySubmittedheding'>Rejection date</Typography>
                                            <Typography className='dateTypography'>{moment(props?.data?.data?.rejected_at).format('YYYY-MMMM-DD')}</Typography></div>
                                    </div>
                                    {props?.data?.data?.approver?.name || !!props?.data?.data?.payer?.name ?
                                        <div className='bottum_spees' style={{ top: "94px" }}></div> : ""}</> : ""}

                            {props?.data?.data?.approver?.name ?
                                <>
                                    <div className='conteanBill_lifecycl'>
                                        <div className='clsTypographydiv'>
                                            <div className='Typography_icon'>
                                                <DotIcon />
                                                <Typography className='clsTypographyheding'>
                                                    Approved by</Typography>
                                            </div>
                                            <Typography className='clsTypographyname'>{props?.data?.data?.approver?.name}</Typography></div>
                                        <div className='Submitted_datedev'>
                                            <Typography className='TypographySubmittedheding'>Approval date</Typography>
                                            <Typography className='dateTypography'>{moment(props?.data?.data?.approved_at).format('YYYY-MMMM-DD')}</Typography></div>
                                    </div>
                                    {!!props?.data?.data?.payer?.name ?
                                        <div className='bottum_spees' style={{ top: "94px" }}></div> : ""}</> : ""}
                            {props?.data?.data?.payer?.name ?
                                <div className='conteanBill_lifecycl'>
                                    <div className='clsTypographydiv'>
                                        <div className='Typography_icon'>
                                            <DotIcon />
                                            <Typography className='clsTypographyheding'>
                                                Paid by</Typography>
                                        </div>
                                        <Typography className='clsTypographyname'>{props?.data?.data?.payer?.name}</Typography></div>
                                    <div className='Submitted_datedev'>
                                        <Typography className='TypographySubmittedheding'>Payout date</Typography>
                                        <Typography className='dateTypography'>{moment(props?.data?.data?.paid_at).format('YYYY-MMMM-DD')}</Typography></div>
                                </div> : ""}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Document_details;

const DotIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 5 5" fill="none">
        <circle cx="2.5" cy="2.5" r="2.5" fill="#025141" />
    </svg>
)