import { Box, Typography } from '@mui/material';
import styles from '../EmailComponents.module.scss';
import { Form } from 'react-bootstrap';

const SubjectLayout = ({ setSubject_text, subject_text, body_text, setBody_text }: any) => {

    return (
        <>
            <Box className={styles.Box_classinput}>
                <Box className={styles.Box_conteansubject}>
                    <Typography className={styles.text_subject}>
                        Subject
                    </Typography>
                    <Form.Control
                        className={styles.inputSubject}
                        name="document_id"
                        value={subject_text}
                        onChange={(e: any) => {
                            setSubject_text(e.target.value)
                        }}
                    />
                </Box>
                <Box className={styles.BoxClasstextarea}>
                    <textarea className={styles.textarea} name="w3review"
                        rows={7}
                        onChange={(e: any) => {
                            setBody_text(e.target.value)
                        }}
                        value={body_text}
                        placeholder=""
                    // cols={58}
                    ></textarea>
                </Box>
            </Box>
        </>
    )
}
export default SubjectLayout;