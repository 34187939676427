import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { userActions } from '../../../_actions';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import '../Auth.css';
import { UserRequest } from '../../../interfaces/interfaces';
import showPwdImg from '../ShowPassword/show-password.svg';
import hidePwdImg from '../ShowPassword/hide-password.svg';
import { LoginHeader } from '../LoginHeader';
import { getUser, isUserAuthenticated } from '../../../_helpers';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { registerSelector } from '../../../_reducers/registration.reducer';
import { useSearchParams } from 'react-router-dom';
import amplitude from 'amplitude-js';
import posthog from 'posthog-js';
import EntityLayout from '../Layout/entityLayout';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { orange } from '@mui/material/colors';
interface Props {
  id: any;
}

export const NewCreate_Account_from = (props: any) => {
  //const alert = useSelector(state => state.alert)
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const [emailid, setemailid] = useState("")
  useEffect(() => {
    const email = searchParams.get('email');
    console.log(email, "emailemail22");

    if (!!email) {
      setemailid(email)
      setUser({
        first_name: '',
        last_name: '',
        email: email,
        password: '',
        confirm_password: '',
        country_code: '+254',
        mobile: '',
        terms: false,
      })
    }
  }, [searchParams]);
  console.log(emailid, "emailidemailid");

  const emailFind: any = 'demo+banking@niobi.co';
  interface countryProps {
    dialCode: string;
  }
  const [user, setUser] = useState<any>({
    first_name: '',
    last_name: '',
    email: searchParams.get('email'),
    password: '',
    confirm_password: '',
    country_code: '+254',
    mobile: '',
    terms: false,
  });
  console.log(user, "useruser");

  const authUser = getUser()?.data ?? null;


  console.log(searchParams, "searchParams");

  const [submitted, setSubmitted] = useState(false);
  const [isRevealPwd, setIsRevealPwd] = useState(false);
  const [phone, setPhone] = useState('');
  const [country_code, setcountry_code] = useState('');
  const [phoneinit, setphoneinit] = useState(true);
  const [registerRender, setregisterRender] = useState(true);
  const [regfailerRender, setregfailerRender] = useState(true);
  const [registered, setregistered] = useState<number>(0);
  const [registerReqeust, setregisterReqeust] = useState(true);
  const [loading, setLoading] = useState(false);

  //const registering = useSelector(state => state.registration.registering)
  const dispatch = useDispatch();

  const registerData = useSelector((state) => registerSelector(state));

  function resetResquest() {
    setregisterRender(true);
    setregfailerRender(true);
  }
  useEffect(() => {
    if (regfailerRender && registerData?.REGISTER_FAILURE !== undefined) {
      setregfailerRender(false);
      setLoading(false);
      setregisterReqeust(true);
    }
  }, [registerData, registerRender]);

  console.log(emailFind, 'emailFind');

  useEffect(() => {
    if (registerRender && registerData?.REGISTER_SUCCESS !== undefined) {
      setregisterRender(false);
      setregistered(registerData.REGISTER_SUCCESS);
      setLoading(false);
      setregisterReqeust(true);
    }
  }, [registerData, registerRender]);

  useEffect(() => {
    if (registerReqeust && registerData?.REGISTER_REQUEST !== undefined) {
      console.log('loading', registerReqeust);
      resetResquest();
      setregisterReqeust(false);
      setLoading(registerData.REGISTER_REQUEST);
    }
  }, [registerData, registerReqeust]);

  const validationSchema = Yup.object().shape({
    first_name: Yup.string().required('Please enter your first name.'),
    last_name: Yup.string().required('Please enter your last name.'),
    email: Yup.string()
      .required('Please enter a valid email address.')
      .email('Please enter a valid email address.'),
    password: Yup.string()
      .required('Please enter your password.')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        'Please enter a strong password.'
      ),
    confirm_password: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords do not match.')
      .required('Please enter your password.'),
    // terms: Yup.bool().oneOf(
    //     [true],
    //     'Please accept the Terms of Use and Privacy Policy.'
    // ),
  });

  const handleSubmit = (e: any) => {
    console.log(e, 'e', phone);
    if (phone.length >= 9) {
      var req = {
        first_name: e.first_name,
        last_name: e.last_name,
        email: e.email,
        password: e.password,
        confirm_password: e.confirm_password,
        country_code: country_code ? country_code : user.country_code,
        mobile: e.mobile ? e.mobile : user.mobile,
        entity_id:
          !!props && !!props.id && !!props.id.entityId
            ? parseInt(props.id.entityId)
            : 0,
        role_id:
          !!props && !!props.id && !!props.id.roleId
            ? parseInt(props.id.roleId)
            : 0,
        team_id:
          !!props && !!props.id && !!props.id.team_id
            ? parseInt(props.id.team_id)
            : 0,
        director_email: !!props?.id?.directorEmail
          ? props?.id?.directorEmail
          : ''
      };

      const event = 'Business Registered';
      const eventProperties = {
        userName: !!req && !!req.email ? req.email : null,
        email:
          !!req && !!req.first_name && !!req.last_name
            ? `${req.first_name} ${req.last_name}`
            : null,
        pageUrl: window.location.href,
      };
      amplitude.getInstance().logEvent(event, eventProperties);
      posthog.capture(event, eventProperties);
      setSubmitted(true);
      if (e.email && e.password) {
        dispatch(userActions.registerByInvite({ req }) as any);
      }
    }
  };
  const handleOnChange = (
    value: any,
    country: any,
    e: any,
    formattedValue: any
  ) => {
    setPhone(formattedValue.substring(country?.dialCode?.length + 1));
    setUser((prev: any) => ({
      ...prev,
      country_code: country?.dialCode,
      mobile: formattedValue.substring(country?.dialCode?.length + 1),
    }));
  };
  const theme = createTheme({
    status: {
      danger: orange[500],
    },
  });
  return (
    <EntityLayout>
      <ThemeProvider theme={theme}>
        <div className="formBx_tow" style={{ overflow: 'scroll' }}>
          <Formik
            initialValues={user}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ errors, touched, isSubmitting, handleChange, values }: any) => {
              { console.log(errors, values, user, "errorserrors") }

              return (
                <Form className="form-input_line">
                  {/* <div id="req_text_atage" className="inputBx" style={{ padding: "50px 0px 35px 0px" }}>
                                    <p style={{fontSize:"12px"}}>
                                        <Link style={{ textDecoration: "none", fontWeight: "600", fontSize: "12PX" }} to="https://niobi.co/request-a-demo/">
                                            Request a demo
                                        </Link>
                                    </p>
                                </div> */}

                  <div
                    className="inputBx"
                    style={{ padding: '50px 0px 35px 0px' }}
                  >
                    <p className="w-100 " style={{ textAlign: 'end' }}>
                      Already have an account? <Link to="../login">Log in</Link>
                    </p>
                  </div>

                  <div className="classname_login_page">
                    <p className="p_Signup_heding">Create Account</p>
                    <div className="first-last-name">
                      <div className="inputBx">
                        <span>First Name</span>
                        <Field
                          name="first_name"
                          type="text"
                          placeholder="First Name"
                          className={
                            'form-text form-control' +
                            (errors.first_name && touched.first_name
                              ? ' is-invalid'
                              : '')
                          }
                        />
                        <ErrorMessage
                          name="first_name"
                          component="div"
                          className="invalid-feedback red"
                        />
                      </div>
                      <div className="inputBx">
                        <span>Last Name</span>
                        <Field
                          name="last_name"
                          type="text"
                          placeholder="Last Name"
                          className={
                            'form-text form-control' +
                            (errors.last_name && touched.last_name
                              ? ' is-invalid'
                              : '')
                          }
                        />
                        <ErrorMessage
                          name="last_name"
                          component="div"
                          className="invalid-feedback red"
                        />
                      </div>
                    </div>
                    <div className="inputBx">
                      <span>Email</span>
                      <Field
                        name="email"
                        type="text"
                        placeholder="Email"
                        disabled
                        value={values?.email}
                        style={{ cursor: 'no-drop', borderColor: '#dddddd' }}
                        className={
                          'form-text form-control ' +
                          (errors.email && touched.email ? ' is-invalid' : '')
                        }
                      />
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="invalid-feedback red"
                      />
                    </div>
                    {/* <div className="inputBx">
                            <span>Mobile</span>
                            <PhoneInput
                                country={'ke'}
                                prefix='+'
                                value={values.mobile}
                                onChange={(value,country,e,formattedValue)=>{
                                    handleOnChange(value,country,e,formattedValue);
                                    handleChange('mobile');
                                } }
                                inputClass={"inputBx-mobile"}
                                autoFormat={false}
                                />
                            </div> */}
                    <div className="inputBx">
                      <span>Mobile</span>
                      <PhoneInput
                        inputProps={{
                          name: 'phone',
                          required: true,
                          autoFocus: true,
                        }}
                        country={'ke'}
                        prefix="+"
                        value={values.mobile}
                        onChange={(
                          value,
                          country: countryProps,
                          e,
                          formattedValue
                        ) => {
                          handleOnChange(value, country, e, formattedValue);
                          handleChange('mobile');
                          console.log(
                            formattedValue,
                            value,
                            country,
                            phone,
                            'formattedValue'
                          );
                          setphoneinit(false);
                          setcountry_code(country.dialCode);
                          //setPhone(formattedValue);
                        }}
                        inputClass={'inputBx-mobile'}
                        autoFormat={false}
                      //onChange={(e) => setPhone(e)}
                      />
                      {phoneinit == false ? (
                        !!phone ? (
                          phone.length < 9 ? (
                            <div className="red invalid-feedback d-block">
                              Please enter a valid phone number with 9 characters
                            </div>
                          ) : (
                            ''
                          )
                        ) : (
                          <div className="red invalid-feedback d-block">
                            Please enter the phone number
                          </div>
                        )
                      ) : (
                        ''
                      )}
                    </div>
                    {/* <div className="inputBx">
                <div className="mobile-no-input">
                  <div className="inputBx-mobile-prefix">
                    <span>Mobile</span>
                    <Field
                      name="country_code"
                      as="select"
                      className={
                        "form-text form-control select-box" +
                        (errors.country_code && touched.country_code
                          ? " is-invalid"
                          : "")
                      }
                    >
                      <option value="+254">+254</option>
                      <option value="+255">+255</option>
                      <option value="+256">+256</option>
                    </Field>
                    <ErrorMessage
                      name="country_code"
                      component="div"
                      className="invalid-feedback red"
                    />
                  </div>
                  <div className="inputBx-mobile">
                    <span></span>
                    <Field
                      name="mobile"
                      type="text"
                      placeholder=""
                      className={
                        "form-text form-control" +
                        (errors.mobile && touched.mobile ? " is-invalid" : "")
                      }
                    />
                    <ErrorMessage
                      name="mobile"
                      component="div"
                      className="invalid-feedback red"
                    />
                  </div>
                </div>
              </div>*/}
                    <div className="inputBx">
                      <span>Password</span>
                      <div
                        className={
                          'pwd-container' +
                          (errors.password && touched.password
                            ? ' is-invalid'
                            : '')
                        }
                      >
                        <Field
                          name="password"
                          placeholder="Password"
                          type={isRevealPwd ? 'text' : 'password'}
                          className={'form-text form-control'}
                          value={values.password}
                          onChange={handleChange('password')}
                        />
                        <img
                          title={isRevealPwd ? 'Hide password' : 'Show password'}
                          src={isRevealPwd ? hidePwdImg : showPwdImg}
                          onClick={() =>
                            setIsRevealPwd((prevState) => !prevState)
                          }
                        />
                      </div>
                      <ErrorMessage
                        name="password"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                    <div className="inputBx">
                      <span>Confirm Password</span>
                      <div
                        className={
                          'pwd-container' +
                          (errors.confirm_password && touched.confirm_password
                            ? ' is-invalid'
                            : '')
                        }
                      >
                        <Field
                          name="confirm_password"
                          placeholder="Confirm Password"
                          type={isRevealPwd ? 'text' : 'password'}
                          className={'form-text form-control'}
                          value={values.confirm_password}
                          onChange={handleChange('confirm_password')}
                        />
                        <img
                          title={isRevealPwd ? 'Hide password' : 'Show password'}
                          src={isRevealPwd ? hidePwdImg : showPwdImg}
                          onClick={() =>
                            setIsRevealPwd((prevState) => !prevState)
                          }
                        />
                      </div>
                      <ErrorMessage
                        name="confirm_password"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>

                    {/* <div className="terms">
                <Field
                  name="terms"
                  type="checkbox"
                  // checked="false"
                  // value="false"
                  className={errors.terms && touched.terms ? ' is-invalid' : ''}
                />
                <span className="pl-1 m-2 font-14">
                  I have read, understood and I agree to Niobi's{' '}
                  <a
                    target="_blank"
                    className=""
                    href="https://niobi.co/wp-content/uploads/2023/02/Niobi_Terms-of-Use_v1.1.pdf"
                  >
                    Terms of Use
                  </a>{' '}
                  and{' '}
                  <a
                    target="_blank"
                    className=""
                    href="https://niobi.co/wp-content/uploads/2023/02/Niobi_Privacy-Policy_v1.1.pdf"
                  >
                    Privacy Policy
                  </a>
                  .
                </span>
                <ErrorMessage
                  name="terms"
                  component="div"
                  className="invalid-feedback red"
                />
              </div> */}

                    {/* <div className='invalid-Login'>
                                <span>The email or password is incorrect. Please retry</span>
                            </div> */}

                    <div className="inputBx">
                      <button
                        type="submit"
                        //disabled={submitted}
                        className="form-button"
                        onClick={() => setphoneinit(false)}
                      >
                        {loading == true && (
                          <span className="spinner-border spinner-border-sm mr-1"></span>
                        )}
                        <span>Create Account</span>
                      </button>
                    </div>
                    <div className="inputBx">
                      <p className="w-100 ">
                        Already have an account? <Link to="../login">Log in</Link>
                      </p>
                    </div>
                  </div>
                  <div
                    className="footer_text"
                    style={{ paddingTop: '20px', paddingBottom: '20px' }}
                  >
                    <a
                      id="a_tageStyles"
                      style={{
                        textDecoration: 'none',
                        fontSize: '12px',
                        paddingRight: '4px',
                        color: '#000',
                      }}
                      target="_blank"
                      className=""
                      href="https://niobi.co/wp-content/uploads/2023/02/Niobi_Terms-of-Use_v1.1.pdf"
                    >
                      Terms of Use
                    </a>{' '}
                    /{' '}
                    <a
                      id="a_tageStyles"
                      style={{
                        textDecoration: 'none',
                        fontSize: '12px',
                        paddingLeft: '4px',
                        color: '#000',
                      }}
                      target="_blank"
                      className=""
                      href="https://niobi.co/wp-content/uploads/2023/02/Niobi_Privacy-Policy_v1.1.pdf"
                    >
                      Privacy Policy
                    </a>
                  </div>
                </Form>
              )
            }}
          </Formik>
        </div>
      </ThemeProvider>
    </EntityLayout>
  );
};
