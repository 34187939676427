
import { Button } from "react-bootstrap";
import styles from './ui.module.scss';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

export const ButtonSave = ({ text, onClick, style, loader }: any) => {
    return (
        <>
            <Button style={style} className={styles.saveButton} onClick={onClick}>
                {text}
                <span style={{ paddingLeft: "3px" }}>
                 {loader == true && <span className="spinner-border spinner-border-sm ml-1"></span>}
                </span>
            </Button>
        </>
    )
}
export const GoBackButton=({onClick,style}:any)=>{

    return(
        <Button style={style} className={styles.GOback_button} onClick={onClick}>
            <KeyboardBackspaceIcon /> Go Back
        </Button>
    )
}