import { Autocomplete, Box, Grid, TextField, Typography } from '@mui/material';
import { GoBackButton } from '../../../../ui/Button';
import styles from './EmailComponents.module.scss';
import { EmailMulti } from './components/EmailMulti';
import React from 'react';
import {
    Form,

} from 'react-bootstrap';
import SubjectLayout from './components/subjectLayout';
const EmailComponents = ({ setSelectedList, selectedList,setSubject_text,subject_text,body_text,setBody_text }: any) => {
    const [focused, setFocused] = React.useState(false);

    return (
        <>
            <Grid container className={styles.gridEmailCom}>
                <Grid item md={12}>
                    <GoBackButton></GoBackButton>
                </Grid>
                <Grid item md={2}>

                </Grid>
                <Grid item md={8} className={styles.Grid_Conteanitem}>
                    <EmailMulti setFocused={setFocused} setEmails={setSelectedList} emails={selectedList} />
                    <SubjectLayout body_text={body_text} setBody_text={setBody_text} subject_text={subject_text} setSubject_text={setSubject_text} />
                </Grid>
            </Grid>

        </>
    )
}
export default EmailComponents;