import { Button } from "react-bootstrap";
import PageHeader from "../PageHeader";
import { ButtonSave } from "../../../ui/Button";
import EmailComponents from "./ComposeEmail/EmailComponents";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { orange } from '@mui/material/colors';
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { paymentJourneyActions } from "../../../../_actions/paymentJourney.actions";
import { useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const ComposeEmail = () => {
    const dispatch = useDispatch();
    let navigate = useNavigate();

    const [loader, setLoader] = useState(false)
    const [selectedList, setSelectedList] = useState<string[]>([])
    const [subject_text, setSubject_text] = useState('Invoice from Niobi');
    const [body_text, setBody_text] = useState(`Hi Grace,\nPlease find the invoice attached as discussed.\nKind regards,\nFaith`)
    const [searchParams, setSearchParams] = useSearchParams();
    const [id, setId] = useState('')

     const [moniteId, setMoniteId] = useState("")
    
    useEffect(() => {
        const popup: any = searchParams.get('invoiceId');
        setMoniteId(popup)
    }, [searchParams])
    

    const theme = createTheme({
        status: {
            danger: orange[500],
        },
    });
    useEffect(() => {
        const id: any = searchParams.get('id');
        setId(id)
    }, [searchParams]);
    console.log(selectedList, body_text, subject_text, "selectedList");


    const SubmitEmail = () => {
        if (selectedList.length == 0) {

        } else {
            const body = {
                "monite_invoice_id": moniteId,
                "body_text": body_text,
                "subject_text": subject_text,
                "emails": selectedList
            }
            // console.log(body,"safdafssaffff");
            setLoader(true);
            
            dispatch(
                paymentJourneyActions.sendInvoiceActive(
                    body
                ) as any
            );
            setTimeout(() => {
                navigate("/invoice")
            }, 3000);
        }
    }

    console.log("moniteId =>", moniteId);
    
    return (
        <>
            <ThemeProvider theme={theme}>
                <PageHeader
                    title="Compose email"
                    extra={
                        <ButtonSave onClick={SubmitEmail} text={'Issue and send'} loader={loader} style={{}}>
                       
                        </ButtonSave>
                    }

                />
                <EmailComponents body_text={body_text} setBody_text={setBody_text} subject_text={subject_text} setSubject_text={setSubject_text} setSelectedList={setSelectedList} selectedList={selectedList} />
            </ThemeProvider>
        </>
    )
}
export default ComposeEmail;