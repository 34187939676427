import Feedback from 'react-bootstrap/esm/Feedback';
import { dispatchStore } from '../../../../Store/store';
import {
  DashboardIcon,
  AccountIcon,
  Payment_LinkIcon,
  WorkspaceIcon,
  ProfileIcon,
  LogoutIcon,
  InflowsIcon,
  OutflowsIcon,
  CategoriesIcon,
  GetHelpIcon,
  BillIcon,
  IntegrationIcon,
  CurrencySelect,
  TeamIcon,
  OrganizationIcon,
  ApprovalsIcon,
  Account_setupIcon,
  AnalyticsIcon,
  BudgetIcon,
  LogoutGreanIcon,
  FeedbackIcon,
  ProductTourIcon,
} from './icon';
const userDetails = localStorage.getItem('user');
export const ROUTES = {
  signin: '/signin',

  counterparts: '/counterparts',
  counterpartsCreate: '/counterparts/create',
  payables: '/payables',
  pay: '/pay',
  payResult: '/pay/:id/result',
  settings: '/settings',
  approvalPolicies: '/settings/approval-policies',
  dashboard: '/dashboard',
  // receivables: "/receivables",
  Counterparts: '/Counterparts',
  products: '/products',
  audit: '/audit',
  gethelp: '/gethelp',
};

export const navigationData: any = [
  {
    label: 'Dashboard',
    url: '/dashboard',
    Icon: <DashboardIcon />,
    apiLink: 'https://docs.monite.com/docs/get-started',
  },
  !!userDetails && JSON.parse(userDetails)?.data?.role_name === 'Associate'
    ? ''
    : {
        label: 'My accounts',
        url: '/balance',
        Icon: <AccountIcon />,
      },
  {
    label: 'Inflows',
    url: '/account',
    Icon: <InflowsIcon />,
    children: [
      {
        label: 'Customers',
        url: '/customers',
        Icon: <AccountIcon />,
        apiLink: '',
      },
      {
        label: 'Products',
        url: '/products',
        Icon: <img src='/target 1.svg' />,
        apiLink: '',
      },
      {
        label: 'Invoices',
        url: '/invoice',
        Icon: <img src="/invoice 1.svg" />,
        apiLink: '',
      },
      {
        label: 'Payment links',
        url: '/settings/Payment_Link',
        Icon: <Payment_LinkIcon />,
        apiLink: '',
      },
      // {
      //   label: 'Request Payment',
      //   url: '/invoice',
      //   Icon: <img src='/image 228.svg' />,
      //   apiLink: '',
      // }
    ],
  },
  {
    label: 'Outflows',
    Icon: <OutflowsIcon />,
    children: [
      !!userDetails && JSON.parse(userDetails)?.data?.role_name === 'Developer'
        ? ''
        : {
            label: 'Bills',
            url: '/bills',
            Icon: <BillIcon />,
            apiLink: '',
          },
      !!userDetails &&
      (JSON.parse(userDetails)?.data?.role_name === 'Director' ||
        JSON.parse(userDetails)?.data?.role_name === 'Approver' ||
        JSON.parse(userDetails)?.data?.role_name === 'Admin')
        ? {
            label: 'Approvals',
            url: ' /Approvals',
            Icon: <ApprovalsIcon />,
            apiLink: '',
          }
        : '',
      !!userDetails && JSON.parse(userDetails)?.data?.role_name === 'Developer'
        ? ''
        : {
            label: 'Payees',
            url: '/counterparts',
            Icon: <img src="/target 1.svg" />,
            apiLink: '',
          },
      {
        label: 'Categories',
        url: '/Categories',
        Icon: <CategoriesIcon />,
        apiLink: '',
      },

      // {
      //   label: 'Request Payment',
      //   url: '/invoice',
      //   Icon: <ApprovalsIcon />,
      //   apiLink: '',
      // }
    ],
  },
  // {
  //   label: 'Tools',
  //   renderIcon: <></>,
  //   children: [
  //     {
  //       label: 'Budget',
  //       url: '/categories',
  //       Icon: <BudgetIcon />,
  //       apiLink: '',
  //     }, {
  //       label: 'Analytics',
  //       url: '/products',
  //       Icon: <AnalyticsIcon />,
  //       apiLink: '',
  //     },
  //   ]
  // },
  {
    label: 'Workspace',
    Icon: <WorkspaceIcon />,
    children: [
      !!userDetails && JSON.parse(userDetails)?.data?.role_name == 'Director'
        ? {
            label: 'Profile',
            url: '/settings/profile',
            Icon: <ProfileIcon />,
            apiLink: '',
          }
        : '',
      !!userDetails &&
      (JSON.parse(userDetails)?.data?.role_name == 'Director' ||
        JSON.parse(userDetails)?.data?.role_name == 'Admin')
        ? {
            label: 'Organization',
            url: '/settings/organisation',
            Icon: <OrganizationIcon />,
            apiLink: '',
          }
        : {
            label: 'Profile',
            url: '/settings/profile',
            Icon: <ProfileIcon />,
            apiLink: '',
          },
      !!userDetails && JSON.parse(userDetails)?.data?.role_name == 'Director'
        ? {
            label: 'Team',
            url: '/settings/team',
            Icon: <TeamIcon />,
            apiLink: '',
          }
        : '',
      // !!userDetails && JSON.parse(userDetails)?.data?.role_name == 'Director'
      //   ? {
      //       label: 'Account Setup',
      //       url: '/settings/account-setup',
      //       Icon: <Account_setupIcon />,
      //       apiLink: '',
      //     }
      //   : '',
      // !!userDetails && JSON.parse(userDetails)?.data?.role_name == 'Director'
      //   ? {
      //       label: 'Verification',
      //       url: '/settings/verification',
      //       Icon: <Account_setupIcon />,
      //       apiLink: '',
      //     }
      //   : '',
      !!userDetails &&
      (JSON.parse(userDetails)?.data?.role_name == 'Director' ||
        JSON.parse(userDetails)?.data?.role_name == 'Admin' ||
        JSON.parse(userDetails)?.data?.role_name == 'Developer')
        ? {
            label: 'API Keys',
            url: '/settings/apikey',
            Icon: <IntegrationIcon />,
            apiLink: '',
          }
        : '',
      !!userDetails &&
      (JSON.parse(userDetails)?.data?.role_name == 'Director' ||
        JSON.parse(userDetails)?.data?.role_name == 'Admin' ||
        JSON.parse(userDetails)?.data?.role_name == 'Developer')
        ? {
            label: 'Currency Accounts',
            url: '/settings/selectCurrency-workspace',
            Icon: <CurrencySelect />,
            apiLink: '',
          }
        : '',
    ],
  },
  {
    label: 'Get Help',
    Icon: <GetHelpIcon />,
    children: [
      {
        label: 'Product Tour',
        url: '/dashboard',
        Icon: <ProductTourIcon />,
      },
      {
        label: 'FAQs',
        url: '/gethelp/FAQs',
        Icon: <img src="/faq 1.svg" />,
      },
      {
        label: 'Raise a Ticket',
        url: '/gethelp/Raise_a_Ticket',
        Icon: <img src="/support-ticket 1.svg" />,
      },
    ],
  },

  {
    label: 'Feedback',
    url: '/feedback',
    Icon: <FeedbackIcon />,
    apiLink: 'https://docs.monite.com/docs/get-started',
  },

  {
    label: 'Logout',
    url: '/Logout',
    Icon: <LogoutGreanIcon />,
    apiLink: 'https://docs.monite.com/docs/get-started',
  },
];
// !!userDetails && JSON.parse(userDetails).data.role_name === 'Associate'
//   ? {
//     dashboard: {
//       label: 'Dashboard',
//       url: '/dashboard',
//       renderIcon: (props) => <DashboardIcon />,
//       apiLink: 'https://docs.monite.com/docs/get-started',
//     },
//     receivables: {
//       label: 'Payees',
//       url: '/Counterparts',
//       renderIcon: (props) => <UInvoice {...props} />,
//       apiLink: 'https://docs.monite.com/docs/receivables-lifecycle',
//     },
//     bills: {
//       label: 'Bills',
//       url: '/bills',
//       renderIcon: (props) => <UUsdCircle {...props} />,
//       children: {
//         profile: {
//           label: 'All Bills',
//           url: '/bills',
//           renderIcon: (props) => <></>,
//         },
//         tags: {
//           label: 'Approvals',
//           url: '/bills-approval',
//           renderIcon: (props) => <> </>,
//           apiLink: 'https://docs.monite.com/docs/receivables-lifecycle',
//         },
//       },
//     },
//     settings: {
//       label: 'Workspace',
//       url: '/settings',
//       renderIcon: (props) => <img src="/assets/Group.svg" />,
//       children: {
//         profile: {
//           label: 'Profile',
//           url: '/settings/profile',
//           renderIcon: (props) => <></>,
//         },
//         tags: {
//           label: 'Categories',
//           url: '/settings/categories',
//           renderIcon: (props) => <> </>,
//           apiLink: 'https://docs.monite.com/docs/manage-counterparts',
//         },
//       },
//     },
//     gethelp: {
//       label: 'Get Help',
//       url: '/gethelp',
//       renderIcon: (props) => <UQuestionCircle {...props} />,
//       children: {
//         profile: {
//           label: 'FAQs',
//           url: '/gethelp/FAQs',
//           renderIcon: (props) => <></>,
//         },
//         tags: {
//           label: 'Raise a Ticket',
//           url: '/gethelp/Raise_a_Ticket',
//           renderIcon: (props) => <> </>,
//           // apiLink: "https://docs.monite.com/docs/manage-counterparts",
//         },
//         organisation: {
//           label: 'Contact Us',
//           url: '/gethelp/Contact_Us',
//           renderIcon: (props) => <></>,
//         },
//       },
//     },
//     logout: {
//       label: 'Logout',
//       url: '/logout',
//       renderIcon: (props) => <UArrowRight {...props} />,
//       onClick: (e) => {
//         e.stopPropagation();
//         e.preventDefault();
//         localStorage.removeItem('_expiredTime');
//         dispatchStore(userActions.logout('logout') as any);
//       },
//     },
//   }
//   : !!userDetails && JSON.parse(userDetails).data.role_name === 'Developer'
//     ? {
//       dashboard: {
//         label: 'Dashboard',
//         url: '/dashboard',
//         renderIcon: (props) => <DashboardIcon />,
//         apiLink: 'https://docs.monite.com/docs/get-started',
//       },

//       balalnce: {
//         label: 'Balance',
//         url: '/balance',
//         renderIcon: (props) => <UUniversity {...props} />,
//         apiLink: 'https://docs.monite.com/docs/manage-counterparts',
//       },
//       // budget: {
//       //   label: "Budget",
//       //   url: "/budget",
//       //   renderIcon: (props) => <img src="/solar_wallet-money-outline.svg" />,
//       //   // apiLink: "https://docs.monite.com/docs/manage-counterparts",
//       // },
//       settings: {
//         label: 'Workspace',
//         url: '/settings',
//         renderIcon: (props) => <img src="/assets/Group.svg" />,

//         children: {
//           // organisation: {
//           //   label: 'Organisation',
//           //   url: '/settings/organisation',
//           //   renderIcon: (props) => <></>,
//           //   apiLink: 'https://docs.monite.com/docs/approval-workflows',
//           // },
//           apikey: {
//             label: 'Integration',
//             url: '/settings/apikey',
//             renderIcon: (props) => <> </>,
//             // apiLink: "https://docs.monite.com/docs/manage-counterparts",
//           },
//           // team: {
//           //   label: 'Team',
//           //   url: '/settings/team',
//           //   renderIcon: (props) => <></>,
//           //   apiLink: 'https://docs.monite.com/docs/approval-workflows',
//           // },
//         },
//       },
//       gethelp: {
//         label: 'Get Help',
//         url: '/gethelp',
//         renderIcon: (props) => <UQuestionCircle {...props} />,

//         children:
//           !!userDetails &&
//             JSON.parse(userDetails).data.role_name != 'Director'
//             ? {
//               profile: {
//                 label: 'FAQs',
//                 url: '/gethelp/FAQs',
//                 renderIcon: (props) => <></>,
//               },
//               tags: {
//                 label: 'Raise a Ticket',
//                 url: '/gethelp/Raise_a_Ticket',
//                 renderIcon: (props) => <> </>,
//                 // apiLink: "https://docs.monite.com/docs/manage-counterparts",
//               },
//               organisation: {
//                 label: 'Contact Us',
//                 url: '/gethelp/Contact_Us',
//                 renderIcon: (props) => <></>,
//               },
//             }
//             : {
//               profile: {
//                 label: 'FAQs',
//                 url: '/gethelp/FAQs',
//                 renderIcon: (props) => <></>,
//               },
//               tags: {
//                 label: 'Raise a Ticket',
//                 url: '/gethelp/Raise_a_Ticket',
//                 renderIcon: (props) => <> </>,
//                 // apiLink: "https://docs.monite.com/docs/manage-counterparts",
//               },
//               organisation: {
//                 label: 'Contact Us',
//                 url: '/gethelp/Contact_Us',
//                 renderIcon: (props) => <></>,
//               },
//             },
//       },
//       logout: {
//         label: 'Logout',
//         url: '/logout',
//         renderIcon: (props) => <UArrowRight {...props} />,
//         onClick: (e) => {
//           e.stopPropagation();
//           e.preventDefault();
//           localStorage.removeItem('_expiredTime');
//           dispatchStore(userActions.logout('logout') as any);
//         },
//       },
//     }
//     : {
//       dashboard: {
//         label: 'Dashboard',
//         url: '/dashboard',
//         renderIcon: (props) => <DashboardIcon />,
//         apiLink: 'https://docs.monite.com/docs/get-started',
//       },
//       accounts: {
//         label: 'My accounts',
//         url: '/account',
//         renderIcon: (props: any) => <AccountIcon />,
//       },
//       Inflows: {
//         label: 'Inflows',
//         url: '/account',
//         renderIcon: (props) => <></>,
//         children: {
//           Customers: {
//             label: 'Customers',
//             url: '/customers',
//             Icon: <AccountIcon />,
//             apiLink: 'https://docs.monite.com/docs/manage-counterparts',
//           },

//         }
//       },
//       receivables: {
//         label: 'Payees',
//         url: '/Counterparts',
//         renderIcon: (props) => <UInvoice {...props} />,
//         apiLink: 'https://docs.monite.com/docs/receivables-lifecycle',
//       },

//       bills: {
//         label: 'Bills',
//         url: '/bills',
//         renderIcon: (props) => <UUsdCircle {...props} />,
//         children: {
//           profile: {
//             label: 'All Bills',
//             url: '/bills',
//             renderIcon: (props) => <></>,
//           },
//           tags: {
//             label: 'Approvals',
//             url: '/bills-approval',
//             renderIcon: (props) => <> </>,
//             apiLink: 'https://docs.monite.com/docs/receivables-lifecycle',
//           },
//         },
//       },

//       approvals: {
//         label: 'Approvals',
//         url: '/approvals',
//         renderIcon: (props) => (
//           <img src="/assets/material-symbols_order-approve-outline.svg" />
//         ),
//         children:
//           !!userDetails &&
//             (JSON.parse(userDetails).data.role_name === 'Director' ||
//               JSON.parse(userDetails).data.role_name === 'Approver' ||
//               JSON.parse(userDetails).data.role_name === 'Admin')
//             ? {
//               profile: {
//                 label: 'Approve Bill',
//                 url: '/approvals/bills-approval',
//                 renderIcon: (props) => <></>,
//               },
//               tags: {
//                 label: 'Approve Bulk Payments',
//                 url: '/approvals/approve_payment',
//                 renderIcon: (props) => <> </>,
//                 // apiLink: "https://docs.monite.com/docs/manage-counterparts",
//               },
//             }
//             : {},
//       },
//       your_invoice: {
//         label: 'Invoice Generator',
//         url: '/invoice',
//         renderIcon: (props) => <InvoiceIcon />,
//         apiLink: 'https://docs.monite.com/docs/manage-counterparts',
//       },
//       Payment_Link: {
//         label: 'Payment Link',
//         url: '/settings/Payment_Link',
//         renderIcon: (props) => (
//           <>
//             <Box
//               sx={{
//                 width: '20px',
//                 '& img': {
//                   width: '100%',
//                 },
//               }}
//             >
//               <img src="/assets/paymentLink.png" />
//             </Box>{' '}
//           </>
//         ),
//       },
//       balalnce: {
//         label: 'Balance',
//         url: '/balance',
//         renderIcon: (props) => <UUniversity {...props} />,
//         apiLink: 'https://docs.monite.com/docs/manage-counterparts',
//       },
//       budget: {
//         label: 'Budget',
//         url: '/budget',
//         renderIcon: (props) => <img src="/solar_wallet-money-outline.svg" />,
//         // apiLink: "https://docs.monite.com/docs/manage-counterparts",
//       },
//       settings: {
//         label: 'Workspace',
//         url: '/settings',
//         renderIcon: (props) => <img src="/assets/Group.svg" />,

//         children:
//           !!userDetails &&
//             JSON.parse(userDetails).data.role_name != 'Director'
//             ? {
//               profile: {
//                 label: 'Profile',
//                 url: '/settings/profile',
//                 renderIcon: (props) => <></>,
//               },
//               tags: {
//                 label: 'Categories',
//                 url: '/settings/categories',
//                 renderIcon: (props) => <> </>,
//                 apiLink: 'https://docs.monite.com/docs/manage-counterparts',
//               },
//             }
//             : {
//               organisation: {
//                 label: 'Organisation',
//                 url: '/settings/organisation',
//                 renderIcon: (props) => <></>,
//                 apiLink: 'https://docs.monite.com/docs/approval-workflows',
//               },
//               verification: {
//                 label: 'Verification',
//                 url: '/settings/verification',
//                 renderIcon: (props) => <></>,
//                 apiLink: 'https://docs.monite.com/docs/approval-workflows',
//               },
//               accountSetup: {
//                 label: 'Account Setup',
//                 url: '/settings/account-setup',
//                 renderIcon: (props) => <></>,
//                 apiLink: 'https://docs.monite.com/docs/approval-workflows',
//               },
//               team: {
//                 label: 'Team',
//                 url: '/settings/team',
//                 renderIcon: (props) => <></>,
//                 apiLink: 'https://docs.monite.com/docs/approval-workflows',
//               },
//               tags: {
//                 label: 'Categories',
//                 url: '/settings/categories',
//                 renderIcon: (props) => <> </>,
//                 apiLink: 'https://docs.monite.com/docs/manage-counterparts',
//               },
//               // your_invoice:{
//               //   label: "Invoice",
//               //   url: "/settings/your_invoice",
//               //   renderIcon: (props) => <> </>,
//               //   apiLink: "https://docs.monite.com/docs/manage-counterparts",
//               // },
//               apikey: {
//                 label: 'Integration',
//                 url: '/settings/apikey',
//                 renderIcon: (props) => <> </>,
//                 // apiLink: "https://docs.monite.com/docs/manage-counterparts",
//               },
//               // Payment_Link: {
//               //   label: "Payment Link",
//               //   url: "/settings/Payment_Link",
//               //   renderIcon: (props) => <> </>,
//               // }
//             },
//       },
//       gethelp: {
//         label: 'Get Help',
//         url: '/gethelp',
//         renderIcon: (props) => <UQuestionCircle {...props} />,

//         children:
//           !!userDetails &&
//             JSON.parse(userDetails).data.role_name != 'Director'
//             ? {
//               profile: {
//                 label: 'FAQs',
//                 url: '/gethelp/FAQs',
//                 renderIcon: (props) => <></>,
//               },
//               tags: {
//                 label: 'Raise a Ticket',
//                 url: '/gethelp/Raise_a_Ticket',
//                 renderIcon: (props) => <> </>,
//                 // apiLink: "https://docs.monite.com/docs/manage-counterparts",
//               },
//               organisation: {
//                 label: 'Contact Us',
//                 url: '/gethelp/Contact_Us',
//                 renderIcon: (props) => <></>,
//               },
//             }
//             : {
//               profile: {
//                 label: 'FAQs',
//                 url: '/gethelp/FAQs',
//                 renderIcon: (props) => <></>,
//               },
//               tags: {
//                 label: 'Raise a Ticket',
//                 url: '/gethelp/Raise_a_Ticket',
//                 renderIcon: (props) => <> </>,
//                 // apiLink: "https://docs.monite.com/docs/manage-counterparts",
//               },
//               organisation: {
//                 label: 'Contact Us',
//                 url: '/gethelp/Contact_Us',
//                 renderIcon: (props) => <></>,
//               },
//             },
//       },
//       logout: {
//         label: 'Logout',
//         url: '/logout',
//         renderIcon: (props) => <UArrowRight {...props} />,
//         onClick: (e) => {
//           e.stopPropagation();
//           e.preventDefault();
//           localStorage.removeItem('_expiredTime');
//           dispatchStore(userActions.logout('logout') as any);
//         },
//       },
//     };
