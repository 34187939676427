
import styles from './Pay.module.scss';
import Setp1 from './components/setp1';
import { useParams } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState, useMemo } from "react";
import { selectPaymentJourneyData } from "../../../../_reducers/paymentJourney.reducer";
import { paymentJourneyActions } from "../../../../_actions/paymentJourney.actions";
import Modal from 'react-bootstrap/Modal'
import countryList from 'react-select-country-list';

const Payment_Link = () => {
  const MockData = {
    amount: 10,
    business_name: "ABC",
    callback_url: "https://dev.wallet.niobi.co/api/pay"
  }
  const { entityid, shortcode } = useParams();
  const [linkRender, setlinkRender] = useState(true);
  const [linkData, setlinkData] = useState();
  const [contryCode, setcountry_code] = useState("KE")

  const dataList = useSelector((state) => selectPaymentJourneyData(state))
  const dispatch = useDispatch();
  const options = useMemo(() => countryList().getData(), [])

  console.log(entityid, shortcode, 'shotcode');

  const [showModal, setShow] = React.useState(false);
  console.log(showModal, "showModal");

  const onClose = () => {
    setShow(false);
  };

  useEffect(() => {
    if (!!shortcode && shortcode != undefined) {
      console.log(shortcode)
      dispatch(paymentJourneyActions.getInvoicePaymentLinkData(shortcode, setlinkRender) as any)
      // setTimeout(() => {
      //   setlinkRender(true);
      // }, 1500)
    }
  }, [shortcode]);

  useEffect(() => {
    if (linkRender && dataList?.INVOICE_PAYMENT_LINK_DATA_SUCCESS !== undefined) {
      setlinkRender(false);
      // var country_sortname_list = dataList.INVOICE_PAYMENT_LINK_DATA_SUCCESS?.payment_methods?.code?.toLowerCase()
      const findcurrencies: any = options.find((data: any) => data.label == dataList?.INVOICE_PAYMENT_LINK_DATA_SUCCESS?.payment_methods?.name)


      var country_sortname_list = findcurrencies?.value?.toLowerCase()
      // console.log(findcurrencies, country_sortname_list,data?.payment_methods?.name,options, "findcurrencies");
      // console.log(country_sortname_list == "Ke", "condisan");
      console.log(country_sortname_list, "country_sortname_list");

      setcountry_code(country_sortname_list)
      setlinkData(dataList.INVOICE_PAYMENT_LINK_DATA_SUCCESS);
      // setcountry_code(country_sortname_list)
      if (dataList?.INVOICE_PAYMENT_LINK_DATA_SUCCESS?.payment_link?.status == "inactive") {
        setShow(true)
      }
    }
  }, [dataList, linkRender]);
  //   useEffect(() => {
  //     if (linkRender && dataList?.PAYMENTLINKSHORTCODE_FAILURE !== undefined) {
  //       setlinkRender(false);
  //       setShow(true)

  //  }
  //   }, [dataList, linkRender]);
  console.log(linkData, shortcode, showModal, 'linkData shortcode')

  return (
    <>
      {
        linkData && shortcode && showModal == false ?
          <Setp1
            data={linkData}
            shortcode={shortcode}
            contryCode={contryCode}
          />
          :
          <Modal
            show={showModal}
            onHide={onClose}
            backdrop="static"
            keyboard={false}
            aria-labelledby='ModalHeader'
            className='topup-modal trans-modal'
            id='afaFafffffFFff'
            centered
          >
            <div className={styles.divapopupdata}>
              <p className={styles.divaptages}>
                We apologize, but the payment link is currently inactive. Please retry with an active Payment link or reach out to <a>support@niobi.co</a> for assistance.
              </p>
            </div>
          </Modal>
      }

    </>
  )
}
export default Payment_Link;