import { ReactMultiEmail, isEmail } from 'react-multi-email';
import 'react-multi-email/dist/style.css';
import styles from '../EmailComponents.module.scss';
import { Box, Typography } from '@mui/material';
export const EmailMulti = ({ emails, setEmails, setFocused }: any) => {

    return (
        <Box className={styles.Box_conteanemail}>
            <Typography className={styles.typo_to}>
                To
            </Typography>
            <ReactMultiEmail
                placeholder=''
                className={styles.ReactMultiEmailstyle}
                emails={emails}
                onChange={(_emails: string[]) => {
                    setEmails(_emails);
                }}
                autoFocus={true}
                onFocus={() => setFocused(true)}
                onBlur={() => setFocused(false)}
                getLabel={(email: any, index: any, removeEmail: any) => {
                    return (
                        <div data-tag key={index}>
                            <div data-tag-item>{email}</div>
                            <span data-tag-handle onClick={() => removeEmail(index)}>
                                ×
                            </span>
                        </div>
                    );
                }}
            />
        </Box>
    )
}