import React, { useEffect, useState } from 'react';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import { Menu, MenuItem, Box, Typography, Tooltip } from '@mui/material';
import { styled } from '@mui/system';
import moment from 'moment';
import Badge from '@mui/material/Badge';
import axios from 'axios';
import { useNotifications } from './NotificationContext';
import DraftsOutlinedIcon from '@mui/icons-material/DraftsOutlined';
import styles from "./styles.module.scss";
import EmailOutlined from '@mui/icons-material/EmailOutlined';

const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        color: theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
        boxShadow:
            'rgb(255, 255, 255) 0px 2px 4px 0px inset, rgb(0, 0, 0) 0px 2px 10px 0px',
        '& .MuiMenu-list': {
            padding: '4px 0',
        },
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5),
            },
            '&:active': {
                backgroundColor: theme.palette.action.selected,
            },
        },
    },
}));

const NotificationBell = () => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const { notifications, unreadCount, markAsRead, markAllAsRead } = useNotifications();

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
        setDropdownOpen(true);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        setDropdownOpen(false);
    };

    return (
        <div>
            <div className="notification-bell" onClick={handleMenuOpen}>
                <Badge badgeContent={unreadCount} color="error">
                    <NotificationsNoneIcon style={{ color: "white" }} />
                </Badge>
            </div>
            <StyledMenu
                id="notification-menu"
                anchorEl={anchorEl}
                open={dropdownOpen}
                onClose={handleMenuClose}
                MenuListProps={{
                    'aria-labelledby': 'notification-button',
                }}
                className={styles.content_two}
            >
                <Box display="flex" justifyContent="space-between" alignItems="center" p={2} borderBottom="1px solid #ccc">
                    <Typography variant="body2" style={{ fontFamily: "Poppins, Verdana", color: "#000000", fontSize: "14px", marginRight: "20px" }}>Notifications</Typography>
                    <Typography variant="body2" onClick={markAllAsRead} style={{ cursor: 'pointer', fontFamily: "Poppins, Verdana", fontWeight: "bold", color: "#025041" }}>Mark All as Read</Typography>
                </Box>
                <Box>
                    {notifications.length === 0 ? (
                        <MenuItem>No new notifications</MenuItem>
                    ) : (
                        notifications
                            .sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp))
                            .slice(0, 5)
                            .map((notification, index) => (
                                <MenuItem 
                                key={index} 
                                component="a" 
                                onClick={() => markAsRead(index, notification.id)} 
                                href= {notification.link !== '' ? notification.link : ""} 
                                style={notification.read_at === null ? { backgroundColor: "#F5F5F5" } : {}} >
                                    <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                                        <Box sx={{ ml: 2, flexGrow: 1 }}>
                                            <Typography variant="body1" style={{ fontFamily: "Poppins, Verdana", fontSize: "14px" }}>{notification.title}</Typography>
                                            <Typography variant="body2" style={{ fontFamily: "Poppins, Verdana", fontSize: "12px" }} color="textSecondary">{notification.message}</Typography>
                                            <Typography variant="caption" style={{ fontFamily: "Poppins, Verdana", fontSize: "12px" }} color="textSecondary">{moment(notification.date).fromNow()}</Typography>
                                        </Box>
                                        {notification.read_at === null && (
                                            <Tooltip title="Mark as read">
                                                <EmailOutlined onClick={() => markAsRead(index, notification.id)} style={{ cursor: 'pointer', marginLeft:"10px" }} />
                                            </Tooltip>
                                        )}
                                         {notification.read_at !== null && (
                                                <DraftsOutlinedIcon onClick={() => markAsRead(index, notification.id)} style={{ cursor: 'pointer', marginLeft:"10px" }} />
                                             )}
                                    </Box>
                                </MenuItem>
                            ))
                    )}
                </Box>
                {notifications.length > 0 && <MenuItem component="a" href="/notifications"><p style={{ textAlign: "center", marginLeft: "90px" }}>Show all notifications</p></MenuItem>}
            </StyledMenu>
        </div>
    );
};
export default NotificationBell;
