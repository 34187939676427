import { Box, Typography } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import styles from './connectInvoice.module.scss';
import ConnectInvoiceTable from './connectInvoiceTable';

const InvoiceConnection = () => {
    const theme = createTheme({
        status: {
          danger: "#025041",
        },
      });
    
    return (
        <>
            <ThemeProvider theme={theme}>
                <>
                <Box className={styles.ContenarInvoice}>
                    <Box>
                        <Typography className={styles.typo_heding}>Connect Invoice with Payments</Typography>
                    </Box>
                </Box>
                <Box className={styles.ContenarTebal}>
                    <ConnectInvoiceTable />
                </Box>
                </>
            </ThemeProvider>
        </>
    )
}
export default InvoiceConnection;