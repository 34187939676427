export const balanceConstants = {
    GETBALANCE_REQUEST: 'GETBALANCE_REQUEST',
    GETBALANCE_SUCCESS: 'GETBALANCE_SUCCESS',
    GETBALANCE_SUCCESS_loading:'GETBALANCE_SUCCESS_loading',
    GETBALANCE_FROM_STORE: 'GETBALANCE_FROM_STORE',
    GETBALANCE_FAILURE: 'GETBALANCE_FAILURE',

    GETBALANCE_REQUESTTWO: 'GETBALANCE_REQUESTTWO',
    GETBALANCE_SUCCESSTWO: 'GETBALANCE_SUCCESSTWO',
    GETBALANCE_SUCCESS_loadingTWO:'GETBALANCE_SUCCESS_loadingTWO', 
    GETBALANCE_FAILURETWO: 'GETBALANCE_FAILURETWO',

    GET_TOPUP_DETAILS_REQUEST: 'GET_TOPUP_DETAILS_REQUEST',
    GET_TOPUP_DETAILS_SUCCESS: 'GET_TOPUP_DETAILS_SUCCESS',
    GET_TOPUP_DETAILS_FROM_STORE: 'GET_TOPUP_DETAILS_FROM_STORE',
    GET_TOPUP_DETAILS_FAILURE: 'GET_TOPUP_DETAILS_FAILURE',
    
    GET_PDF_REQUEST:"GET_PDF_REQUEST",
    GET_PDF_SUCCESS:"GET_PDF_SUCCESS",
    GET_PDF_FAILURE:"GET_PDF_FAILURE",

    GETCURRENCIES_REQUEST:"GETCURRENCIES_REQUEST",
    GETCURRENCIES_SUCCESS:"GETCURRENCIES_SUCCESS",
    GETCURRENCIES_FAILURE:"GETCURRENCIES_FAILURE",

    GET_TOPUP_PDF_REQUEST:"GET_TOPUP_PDF_REQUEST",
    GET_TOPUP_PDF_SUCCESS:"GET_TOPUP_PDF_SUCCESS",
    GET_TOPUP_PDF_FAILURE:"GET_TOPUP_PDF_FAILURE",

    GET_TOPUP_PDF_STK_REQUEST:"GET_TOPUP_PDF_STK_REQUEST",
    GET_TOPUP_PDF_STK_SUCCESS:"GET_TOPUP_PDF_STK_SUCCESS",
    GET_TOPUP_PDF_STK_FAILURE:"GET_TOPUP_PDF_STK_FAILURE",

    STK_DEPOSIT_REQUEST:"STK_DEPOSIT_REQUEST",
    STK_DEPOSIT_SUCCESS:"STK_DEPOSIT_SUCCESS",
    STK_DEPOSIT_FAILURE:"STK_DEPOSIT_FAILURE",
    GET_BALANCE_BALANCE_SUCCESS: 'GET_BALANCE_BALANCE_SUCCESS',
    GET_BALANCE_BILL_SUCCESS: 'GET_BALANCE_BILL_SUCCESS',
    GET_CSV_EXPORT_REQUEST: 'GET_CSV_EXPORT_REQUEST',
    GET_CSV_EXPORT_SUCCESS: 'GET_CSV_EXPORT_SUCCESS',
    GET_CSV_EXPORT_FAILURE: 'GET_CSV_EXPORT_FAILURE',

    GET_BANK_DETAILS_ID_REQUEST: 'GET_BANK_DETAILS_ID_REQUEST',
    GET_BANK_DETAILS_ID_SUCCESS: 'GET_BANK_DETAILS_ID_SUCCESS',
    GET_BANK_DETAILS_ID_FAILURE: 'GET_BANK_DETAILS_ID_FAILURE',

    SAVE_PAYMENT_CURRENCY_DETAILS_REQUEST: 'SAVE_PAYMENT_CURRENCY_DETAILS_REQUEST',
    SAVE_PAYMENT_CURRENCY_DETAILS_SUCCESS: 'SAVE_PAYMENT_CURRENCY_DETAILS_SUCCESS',
    SAVE_PAYMENT_CURRENCY_DETAILS_FAILURE: 'SAVE_PAYMENT_CURRENCY_DETAILS_FAILURE',

    SAVE_PAYMENT_DETAILS_REQUEST: 'SAVE_PAYMENT_DETAILS_REQUEST',
    SAVE_PAYMENT_DETAILS_SUCCESS: 'SAVE_PAYMENT_DETAILS_SUCCESS',
    SAVE_PAYMENT_DETAILS_FAILURE: 'SAVE_PAYMENT_DETAILS_FAILURE',

    POST_KES_PAYMENT_REQUEST: 'POST_KES_PAYMENT_REQUEST',
    POST_KES_PAYMENT_SUCCESS: 'POST_KES_PAYMENT_SUCCESS',
    POST_KES_PAYMENT_FAILURE: 'POST_KES_PAYMENT_FAILURE',

    TRANTOPUP_REQUEST:"TRANTOPUP_REQUEST",
    TRANTOPUP_SUCCESS:"TRANTOPUP_SUCCESS",
    TRANTOPUP_FAILURE:"TRANTOPUP_FAILURE",

    TRANSACTIONTEAM_REQUEST: "TRANSACTIONTEAM_REQUEST",
    TRANSACTIONTEAM_SUCCESS: "TRANSACTIONTEAM_SUCCESS",
    TRANSACTIONTEAM_FAILURE: "TRANSACTIONTEAM_FAILURE"

}