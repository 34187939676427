import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  Paper,
  Select,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import styles from "./Customers_Tebal.module.scss";
import { Button, Form, InputGroup, Table } from "react-bootstrap";
import { Input } from "../../../FromComponents/from";
import React, { useEffect, useState } from "react";
import TableContainer from "@mui/material/TableContainer";
import moment from "moment";
import Checkbox from "@mui/material/Checkbox";
import { Menu, MenuItem } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import { selectAllCounterpartsListData } from "../../../../../../_reducers/counterparts.reducer";
import {
  getUser,
  isUserAuthenticated,
  authHeader,
} from "../../../../../../_helpers";
import { counterpartsActions } from "../../../../../../_actions/counterparts.actions";
import axios from "axios";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { ListSkeleton, SkeletonDashboardV3, SkeletonSelectBox } from "../../../Skeleton/Skeleton";
import { dashboardActions } from "../../../../../../_actions";
import {
  selectAllData,
} from '../../../../../../_reducers/dashboard.reducer';
const Customers_Tebal = ({ onSave }: any) => {
  const [loderinapi, setLoderapi] = React.useState(false);
  const [search, setSearch] = React.useState("");
  const [payablesList, setPayablesList] = useState<any>([]);
  const [render, setRender] = React.useState(true);
  const [ideditdata, setIdeditdata] = React.useState("");
  const [userId, setUserId] = useState("");
  const [item, setItem] = useState({ selectedItem: "" });
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [selected, setSelected] = React.useState<readonly string[]>([]);
  const [showModal, setShow] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [totalCount, setTotalCount] = React.useState(10);
  const [pageneshn, setPagelist] = React.useState(1);
  const [remove, setRemovedata] = React.useState(10);
  const [ascending, setAscending] = React.useState("DESC");
  const [type, setType] = React.useState("");
  const [ascending_date, setAscending_date] = React.useState("DESC");
  const [TypeSelect, setType_Select] = useState([
    {
      name: "Individual",
      value: "individual",
    },
    {
      name: "Organization",
      value: "organization",
    },
  ]);
  const [selectCurrencyPopup, setSelectCurrencyPopup] = useState<any>('KES')
  const [tebalType, setTebalType] = useState("");
  const [currencyAndMethodData, setCurrencyAndMethodData] = useState([]);
  const dispatch = useDispatch();

  const data = useSelector((state) => selectAllData(state));

  function doneTyping() {
    var serdata: any = {
      search: search,
      pagedata: 1,
    };
    setPagelist(1)
    setRemovedata(10)
    getCustomer(search, 1, ascending, type, tebalType,selectCurrencyPopup);
  }
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      doneTyping();
      console.log("usereffect testing");
    }, 1000);
    return () => clearTimeout(timeoutId);
  }, [search]);
  React.useEffect(() => {
    if (isUserAuthenticated() && render) {
      setRender(false);
      getCustomer(search, pageneshn, ascending, type, tebalType,selectCurrencyPopup);
    }
  }, [render, isUserAuthenticated]);
  React.useEffect(() => {
    getCustomer(search, pageneshn, ascending, type, tebalType,selectCurrencyPopup);
  }, [ascending, type, pageneshn, tebalType,selectCurrencyPopup]);
  const getCustomer = async (
    search: any,
    pageneshn: any,
    ascending: any,
    type: any,
    typeList: any,
    selectCurrencyPopup:any
  ) => {
    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json", ...authHeader() },
    };
    setLoading(true);
    var limet = 10;
    const response = await axios
      .get(
        `${process.env.REACT_APP_API_URL}/customers/get-customer-list?search=${search}&page=${pageneshn}&limit=${limet}&order=${ascending}&order_by=${type}&type=${typeList}&currency=${selectCurrencyPopup}`,
        requestOptions
      )
      .then((response) => {
        if (response.data.success == true) {
          setPayablesList(response.data.data.data);
          setTotalCount(response.data.data.total);
          setLoading(false);
        } else {
          console.log("errr");
          setLoading(false);
        }
        console.log(response, "response");
        setLoading(false);
      });
  };
  useEffect(() => {
    dispatch(dashboardActions.getDashboardCurrencyData() as any)
  }, [dispatch])

  useEffect(() => {
    if (data && data.GET_DASHBOARD_CURRENCY_DATA_SUCCESS !== undefined) {
      setCurrencyAndMethodData(data.GET_DASHBOARD_CURRENCY_DATA_SUCCESS.data);
    }
  }, [data.GET_DASHBOARD_CURRENCY_DATA_SUCCESS])
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const handleShow_PopUp = () => {
    setShow(true);
  };
  const onClose = () => {
    setShow(false);
  };
  const handleClick_datat = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      // const newSelected = payablesList.map((n: any) => n.id);
      // setSelected(newSelected);
      // return;
      const newSelected: any = []
      for (let index = 0; index < payablesList.length; index++) {
        const element = payablesList[index];
        // if (element.status == "paid") {

        // } else {
        newSelected.push(element.id)
        // }
      }
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  // const isSelected = (name: any) => selected.indexOf(name) !== -1;
  const handleClick = (event: React.MouseEvent<unknown>, name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: readonly string[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };
  const deleteCustomer = () => {
    dispatch(counterpartsActions.deleteCustomer(ideditdata) as any);
    setTimeout(() => {
      getCustomer(search, pageneshn, ascending, type, tebalType,selectCurrencyPopup);
    }, 3000);
    setSelected([]);
  };
  const AllDeleteCustomer = () => {
    var body = {
      ids: selected,
    };
    dispatch(counterpartsActions.AlldeleteCustomer(body) as any);
    setTimeout(() => {
      getCustomer(search, pageneshn, ascending, type, tebalType,selectCurrencyPopup);
    }, 3000);
    setSelected([]);
  };
  // function doneTyping() {

  //     getCustomer(search, pageneshn, ascending, type);
  //     setBalanceListRender(true);
  //     setrequestRender(true);
  //     setLoader(true);
  //   }
  // useEffect(() => {
  //       const timeoutId = setTimeout(() => {
  //         doneTyping();
  //         // console.log('usereffect testing');
  //       }, 1000);
  //       return () => clearTimeout(timeoutId);

  //   }, [search]);
  const isSelected = (name: string) => selected.indexOf(name) !== -1;

  return (
    <>
      <Grid container spacing={1.9} className={styles.Customers_grid_container}>
        <SkeletonSelectBox
          width={"100%"}
height={'60px'}
          loader={loading}
          style={{ textAlign: "center", marginLeft: "13px" }}
          element={
            <>
              <Grid item md={3} sm={12} xs={12}>
                <InputGroup className="balance__filter-wrapper input">
                  <InputGroup.Text id="basic-addon1" className="icon">
                    <SearchIcon />
                  </InputGroup.Text>
                  <Form.Control
                    className="balance-search-input"
                    placeholder="Search by Name"
                    aria-label="Search"
                    aria-describedby="basic-addon1"
                    value={search}
                    disabled={loading}
                    onChange={(event) => {
                      console.log("testing");
                      if (event.target.value == "") {
                        setSearch(event.target.value);
                      } else {
                        setSearch(event.target.value);
                      }
                    }}
                  />
                </InputGroup>
              </Grid>
              <Grid item md={3}>
                <Form.Select
                  name="supplier_id"
                  onChange={(e: any) => {
                    setTebalType(e.target.value);

                    getCustomer(
                      search,
                      pageneshn,
                      ascending,
                      type,
                      e.target.value,
                      selectCurrencyPopup
                    );
                  }}
                  value={tebalType}
                  className={styles.selectBoxcalss}
                >
                  <option className={styles.optionSelect} selected value="">
                    Select Type
                  </option>
                  {TypeSelect?.map((arrayElement: any, index: number) => {
                    return (
                      <option
                        className={styles.optionSelect}
                        value={arrayElement.value}
                        key={index + 1}
                      >
                        {arrayElement.name}
                      </option>
                    );
                  })}
                </Form.Select>
              </Grid>
              <Grid item md={3}>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  className="selectBox"
                  value={selectCurrencyPopup}
                  // sx={{
                  //   p: {
                  //     display: "none"
                  //   }
                  // }}
                  onChange={(e: any) => {
                    setSelectCurrencyPopup(e.target.value)

                  }}
                >
                  {Array.isArray(currencyAndMethodData) && currencyAndMethodData?.map((item: any, index: number) => {
                    return (
                      <MenuItem
                        value={item.currency}
                        key={index}>
                        <Box className="MenuItemBox" >
                          <img
                            loading="lazy"
                            width="25"
                            src={item.flag}
                            alt=""
                          />
                          <p>
                            {item.currency}
                          </p>
                        </Box>
                      </MenuItem>
                    )
                  })}
                </Select>
              </Grid>
              <Grid item md={3}>

              </Grid>
            </>
          }
        ></SkeletonSelectBox>
        <Grid item md={12} xs={12}>
          <SkeletonDashboardV3
            width={"100%"}
            height={'450px'}
            loader={loading}
            element={
              <div className="payablegrid__table">
                <TableContainer className={styles.Customers_TableContainer}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        {headCells.map((el) => (
                          <>
                            {el.id == "Checkbox" ? (
                              <TableCell padding="checkbox">
                                <Checkbox
                                  icon={<img src="/Checkbox.svg" />}
                                  checkedIcon={<img src="/Checkbox_two.svg" />}
                                  color="primary"
                                  indeterminate={
                                    selected.length > 0 &&
                                    selected.length < payablesList.length
                                  }
                                  checked={
                                    payablesList.length > 0 &&
                                    selected.length === payablesList.length
                                  }
                                  onChange={handleSelectAllClick}
                                  inputProps={{
                                    "aria-label": "select all desserts",
                                  }}
                                />
                              </TableCell>
                            ) : el.id == "counterpart_name" ? (
                              <TableCell>
                                <div
                                  style={{ display: "flex", cursor: "pointer" }}
                                  onClick={() => {
                                    if (ascending == "DESC") {
                                      setAscending("ASC");
                                      setType(el.id);
                                      setAscending_date("ASC");
                                    } else {
                                      setAscending("DESC");
                                      setAscending_date("DESC");
                                      setType(el.id);
                                    }
                                  }}
                                >
                                  {el.label}
                                  {ascending_date == "DESC" &&
                                    el.id == "counterpart_name" ? (
                                    <ArrowDropDownIcon />
                                  ) : (
                                    <ArrowDropUpIcon />
                                  )}
                                </div>
                              </TableCell>
                            ) : (
                              <TableCell>{el.label}</TableCell>
                            )}
                          </>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {payablesList?.map((item: any, index: any) => {
                        const isItemSelected = isSelected(item.id);
                        const labelId = `enhanced-table-checkbox-${index}`;
                        console.log(isItemSelected, "isItemSelected");

                        return (
                          <TableRow
                            role="checkbox"
                            // aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={index}
                            // selected={isItemSelected}
                            sx={{ cursor: 'pointer' }}
                          >
                            <TableCell padding="checkbox">
                              <Checkbox
                                color="primary"
                                icon={<img src="/Checkbox.svg" />}
                                checkedIcon={<img src="/Checkbox_two.svg" />}
                                // name={`users.${index}.is_current_price`}
                                checked={isItemSelected}
                                onClick={(event: any) => {
                                  handleClick(event, item.id);
                                }}
                                inputProps={{
                                  'aria-labelledby': labelId,
                                }}
                              />
                            </TableCell>
                            <TableCell
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                navigate("/customers?id=" + item?.id, {
                                  replace: true,
                                  state: { id: item?.id },
                                });
                              }}
                            >
                              {item.name}
                            </TableCell>
                            <TableCell>
                              {moment(item.created_at).format("DD-MMM-YYYY")}
                            </TableCell>
                            <TableCell>
                              <Box>
                                <div className={styles.Total_maindiv}>
                                  <Typography
                                    className={styles.TypographystylesHeading}
                                  >
                                    Total Number
                                  </Typography>
                                  <Typography
                                    className={styles.Typographystyles}
                                    style={{ paddingLeft: "6px" }}
                                  >
                                    {item?.invoices?.paid?.paid_payable_count
                                      ? item?.invoices?.paid?.paid_payable_count
                                      : "0"}
                                  </Typography>
                                </div>
                                <div
                                  className={styles.Total_maindiv}
                                  style={{ paddingTop: "8px" }}
                                >
                                  <Typography
                                    className={styles.TypographystylesHeading}
                                  >
                                    Total value
                                  </Typography>
                                  <Typography
                                    className={styles.Typographystyles}
                                    style={{ paddingLeft: "23px" }}
                                  >
                                    {item.currency}{" "}
                                    {item?.invoices?.paid?.paid_amount
                                      ? item?.invoices?.paid?.paid_amount
                                      : "0"}
                                  </Typography>
                                </div>
                              </Box>
                            </TableCell>
                            <TableCell>
                              <Box>
                                <div className={styles.Total_maindiv}>
                                  <Typography
                                    className={styles.TypographystylesHeading}
                                  >
                                    Total Number
                                  </Typography>
                                  <Typography
                                    className={styles.Typographystyles}
                                    style={{ paddingLeft: "6px" }}
                                  >
                                    {item?.invoices?.outstanding
                                      ?.outstanding_payable_count
                                      ? item?.invoices?.outstanding
                                        ?.outstanding_payable_count
                                      : "0"}
                                  </Typography>
                                </div>
                                <div
                                  className={styles.Total_maindiv}
                                  style={{ paddingTop: "8px" }}
                                >
                                  <Typography
                                    className={styles.TypographystylesHeading}
                                  >
                                    Total value
                                  </Typography>
                                  <Typography
                                    className={styles.Typographystyles}
                                    style={{ paddingLeft: "23px" }}
                                  >
                                    {item.currency}{" "}
                                    {item?.invoices?.outstanding
                                      ?.oustanding_amount
                                      ? item?.invoices?.outstanding
                                        ?.oustanding_amount
                                      : "0"}
                                  </Typography>
                                </div>
                              </Box>
                            </TableCell>
                            <TableCell>
                              <Box>
                                <div className={styles.infoclass}>
                                  <EmailIcon />
                                  <Typography
                                    className={
                                      styles.Typographystyles_infoclass
                                    }
                                  >
                                    {item?.contact_email}
                                  </Typography>
                                </div>
                                <div
                                  className={styles.infoclass}
                                  style={{ paddingTop: "8px" }}
                                >
                                  <PhoneIcon />
                                  <Typography
                                    className={
                                      styles.Typographystyles_infoclass
                                    }
                                  >
                                    {item?.currancy}
                                    {item?.contact_phone}
                                  </Typography>
                                </div>
                              </Box>
                            </TableCell>
                            <TableCell>
                              <IconButton
                                onClick={(e: any) => {
                                  handleClick_datat(e);
                                  setIdeditdata(item?.monite_customer_id);
                                }}
                              >
                                <MenuIcon />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
                <div className={styles.addnewbtnclass}>
                  <Button
                    disabled={selected.length > 0 ? false : true}
                    onClick={() => AllDeleteCustomer()}
                    color="#F87168"
                    className="white_btn_two"
                  >
                    Delete
                  </Button>
                  <div className={styles.pageNeshan}>
                    <div className={styles.inpagenum}>
                      <p>
                        {remove - 9}-{remove}
                      </p>
                    </div>
                    <div className={styles.btnmaindivin}>
                      {pageneshn == 1 ? (
                        <button disabled className={styles.nextntmistish}>
                          <ArrowBackIosNewIcon style={{ fontSize: "16px" }} />
                        </button>
                      ) : (
                        <button
                          className={styles.nextntmistish}
                          onClick={() => {
                            setPagelist(pageneshn - 1);
                            setRemovedata(remove - 10);
                          }}
                        >
                          <ArrowBackIosNewIcon style={{ fontSize: "16px" }} />
                        </button>
                      )}
                      {remove >= totalCount ? (
                        <button disabled className={styles.nextntmistish}>
                          <ArrowForwardIosIcon style={{ fontSize: "16px" }} />
                        </button>
                      ) : (
                        <button
                          className={styles.nextntmistish}
                          onClick={() => {
                            setPagelist(pageneshn + 1);
                            setRemovedata(remove + 10);
                          }}
                        >
                          <ArrowForwardIosIcon style={{ fontSize: "16px" }} />
                        </button>
                      )}
                    </div>
                  </div>
                </div>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <MenuItem
                    onClick={() => {
                      navigate("/create-customer?id=" + ideditdata, {
                        replace: true,
                        state: { id: ideditdata },
                      });
                    }}
                    className="menuitem"
                  >
                    Update
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      handleClose();
                      // handleClickOpendelog()
                      handleShow_PopUp();
                      // deleteData()
                    }}
                    className="menuitem"
                    style={{ color: "#F87161" }}
                  >
                    Delete
                  </MenuItem>
                </Menu>
                <Modal
                  show={showModal}
                  onHide={onClose}
                  backdrop="static"
                  keyboard={false}
                  aria-labelledby="ModalHeader"
                  // className='topup-modal'
                  centered
                  size="sm"
                >
                  <Modal.Body>
                    <Box className={styles.box_dialog_main}>
                      <Typography className={styles.typo_text}>
                        Are you sure you want to remove this customer?
                      </Typography>
                      <Box className={styles.box_btn_cencal}>
                        <Button className={styles.cancel_btn} onClick={onClose}>
                          Close
                        </Button>
                        <Button
                          className={styles.delete_btn}
                          onClick={() => {
                            onClose();
                            deleteCustomer();
                          }}
                        >
                          Delete
                        </Button>
                      </Box>
                    </Box>
                  </Modal.Body>
                </Modal>
              </div>
            }
          ></SkeletonDashboardV3>
        </Grid>
      </Grid>
    </>
  );
};
export default Customers_Tebal;
const SearchIcon = () => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13 13L10.0748 10.1341M11.6667 6.33334C11.6667 9.27886 9.27885 11.6667 6.33333 11.6667C3.38781 11.6667 1 9.27886 1 6.33334C1 3.38782 3.38781 1 6.33333 1C9.27885 1 11.6667 3.38782 11.6667 6.33334Z"
      stroke="#111111"
      stroke-width="1.1875"
      stroke-miterlimit="1"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
const EmailIcon = () => (
  <svg
    width="15"
    height="11"
    viewBox="0 0 15 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.125 0.8125H1.875C1.62636 0.8125 1.3879 0.911272 1.21209 1.08709C1.03627 1.2629 0.9375 1.50136 0.9375 1.75V9.25C0.9375 9.49864 1.03627 9.7371 1.21209 9.91291C1.3879 10.0887 1.62636 10.1875 1.875 10.1875H13.125C13.3736 10.1875 13.6121 10.0887 13.7879 9.91291C13.9637 9.7371 14.0625 9.49864 14.0625 9.25V1.75C14.0625 1.50136 13.9637 1.2629 13.7879 1.08709C13.6121 0.911272 13.3736 0.8125 13.125 0.8125ZM12.0938 1.75L7.5 4.92812L2.90625 1.75H12.0938ZM1.875 9.25V2.17656L7.23281 5.88437C7.31128 5.93881 7.4045 5.96798 7.5 5.96798C7.5955 5.96798 7.68872 5.93881 7.76719 5.88437L13.125 2.17656V9.25H1.875Z"
      fill="#6D6B6B"
    />
  </svg>
);
const PhoneIcon = () => (
  <svg
    width="13"
    height="13"
    viewBox="0 0 13 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.4688 12.125C10.1667 12.125 8.88021 11.8413 7.60938 11.2738C6.33854 10.7063 5.18229 9.90146 4.14063 8.85938C3.09896 7.81771 2.29437 6.66146 1.72687 5.39062C1.15937 4.11979 0.875417 2.83333 0.875 1.53125C0.875 1.34375 0.9375 1.1875 1.0625 1.0625C1.1875 0.9375 1.34375 0.875 1.53125 0.875H4.0625C4.20833 0.875 4.33854 0.924583 4.45313 1.02375C4.56771 1.12292 4.63542 1.24 4.65625 1.375L5.0625 3.5625C5.08333 3.72917 5.07812 3.86979 5.04688 3.98438C5.01562 4.09896 4.95833 4.19792 4.875 4.28125L3.35938 5.8125C3.56771 6.19792 3.815 6.57021 4.10125 6.92938C4.3875 7.28854 4.70271 7.635 5.04688 7.96875C5.36979 8.29167 5.70833 8.59125 6.0625 8.8675C6.41667 9.14375 6.79167 9.39625 7.1875 9.625L8.65625 8.15625C8.75 8.0625 8.8725 7.99229 9.02375 7.94562C9.175 7.89896 9.32333 7.88583 9.46875 7.90625L11.625 8.34375C11.7708 8.38542 11.8906 8.46104 11.9844 8.57062C12.0781 8.68021 12.125 8.8025 12.125 8.9375V11.4688C12.125 11.6563 12.0625 11.8125 11.9375 11.9375C11.8125 12.0625 11.6563 12.125 11.4688 12.125ZM2.76562 4.625L3.79688 3.59375L3.53125 2.125H2.14062C2.19271 2.55208 2.26562 2.97396 2.35938 3.39062C2.45312 3.80729 2.58854 4.21875 2.76562 4.625ZM8.35938 10.2188C8.76562 10.3958 9.17979 10.5365 9.60187 10.6406C10.024 10.7448 10.4483 10.8125 10.875 10.8438V9.46875L9.40625 9.17188L8.35938 10.2188Z"
      fill="#6D6B6B"
    />
  </svg>
);
const MenuIcon = () => (
  <svg
    width="4"
    height="16"
    viewBox="0 0 4 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 8C0 8.53043 0.210714 9.03914 0.585787 9.41421C0.96086 9.78929 1.46957 10 2 10C2.53043 10 3.03914 9.78929 3.41421 9.41421C3.78929 9.03914 4 8.53043 4 8C4 7.46957 3.78929 6.96086 3.41421 6.58579C3.03914 6.21071 2.53043 6 2 6C1.46957 6 0.96086 6.21071 0.585787 6.58579C0.210714 6.96086 0 7.46957 0 8ZM0 2C0 2.53043 0.210714 3.03914 0.585787 3.41421C0.96086 3.78929 1.46957 4 2 4C2.53043 4 3.03914 3.78929 3.41421 3.41421C3.78929 3.03914 4 2.53043 4 2C4 1.46957 3.78929 0.960859 3.41421 0.585786C3.03914 0.210714 2.53043 0 2 0C1.46957 0 0.96086 0.210714 0.585787 0.585786C0.210714 0.960859 0 1.46957 0 2ZM0 14C0 14.5304 0.210714 15.0391 0.585787 15.4142C0.96086 15.7893 1.46957 16 2 16C2.53043 16 3.03914 15.7893 3.41421 15.4142C3.78929 15.0391 4 14.5304 4 14C4 13.4696 3.78929 12.9609 3.41421 12.5858C3.03914 12.2107 2.53043 12 2 12C1.46957 12 0.96086 12.2107 0.585787 12.5858C0.210714 12.9609 0 13.4696 0 14Z"
      fill="#6D6B6B"
    />
  </svg>
);
interface HeadCell {
  id: string;
  label: string;
}

const headCells: readonly HeadCell[] = [
  {
    id: "Checkbox",
    label: "",
  },
  {
    id: "counterpart_name",
    label: "Name",
  },
  {
    id: "Created_at",
    label: "Created at",
  },
  {
    id: "Invoices_sent",
    label: "Invoices sent",
  },
  {
    id: "Invoices_paid",
    label: "Invoices paid",
  },
  {
    id: "Contact_information",
    label: "Contact information",
  },
  {
    id: "menu",
    label: "",
  },
];
const TableHeaderMockData = [
  "Checkbox",
  "Name",
  "Created at",
  "Invoices sent",
  "Invoices paid",
  "Contact information",
  "",
];
