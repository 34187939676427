import { Box, Grid, styled } from "@mui/material";
import Skeleton from '@mui/material/Skeleton';


export const SkeletonCom = ({ loader, element }: any) => {

    return (
        <>
            {loader ?
                <Skeleton variant="rectangular"
                    // width={'90%'}
                    sx={{ textAlign: "center" }}
                >
                    <Box sx={{ width: "90%" }}>
                        {element}
                    </Box>
                </Skeleton> :
                <>
                    {element}
                </>}
        </>
    )
}

export const SkeletonDashboardV3 = ({ loader, element, height, width,style }: any) => {

    return (
        <>
            {loader ?
                <Skeleton variant="rounded"
                    width={width}
                    height={height}
                    sx={{ textAlign: "center" }}
                >
                    <Box
                    // sx={{width:"90%",height:"100%"}}
                    >
                        {element}
                    </Box>
                </Skeleton> :
                <>
                    {element}
                </>}
        </>
    )
}
export const SkeletonSelectBox = ({ loader, element, height, width,style }: any) => {

    return (
        <>
            {loader ?
                <Skeleton variant="rounded"
                    width={width}
                    height={height}
                    sx={style}
                >
                    <Box
                    // sx={{width:"90%",height:"100%"}}
                    >
                        {element}
                    </Box>
                </Skeleton> :
                <>
                    {element}
                </>}
        </>
    )
}
export const SkeletonBill = ({ loader, element, height, width }: any) => {

    return (
        <>
            {loader ?
                <Skeleton variant="rounded"
                    width={width}
                    height={height}
                    sx={{ textAlign: "center" }}
                >
                    <Grid container>
                        <Grid item md={8}>

                        </Grid>
                    </Grid>
                </Skeleton> :
                <>
                    {element}
                </>}
        </>
    )
}

export const ListSkeleton = ({ loader, element, height, width }: any) => {

    return (
        <>
            {loader ?
                <Skeleton variant="rounded"
                    width={width}
                    height={height}
                    sx={{ textAlign: "center" }}
                >
                    <Grid container>
                        <Grid item md={8}>

                        </Grid>
                    </Grid>
                </Skeleton> :
                <>
                    {element}
                </>}
        </>
    )
}