import { Grid, Paper, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import styles from './invoices_Statements.module.scss';
import { Table } from 'react-bootstrap';
import moment from 'moment';
import React from 'react';
import { TebalNoData } from '../../../Skeleton/TebalNoData';

interface HeadCell {
    disablePadding: boolean;
    id: any;
    label: string;
    numeric: boolean;
}
const headCells: readonly HeadCell[] = [
    {
        id: 'Number',
        numeric: false,
        disablePadding: true,
        label: 'Number',
    },
    {
        id: 'Customer',
        numeric: true,
        disablePadding: false,
        label: 'Customer',
    },
    {
        id: 'Invoice_date',
        numeric: true,
        disablePadding: false,
        label: 'Invoice date',
    },
    {
        id: 'Issuing_date',
        numeric: true,
        disablePadding: false,
        label: 'Issuing date',
    },

    {
        id: 'Due_date',
        numeric: true,
        disablePadding: false,
        label: 'Due date',
    },
    {
        id: 'Type',
        numeric: true,
        disablePadding: false,
        label: 'Type',
    },
    {
        id: 'Amount',
        numeric: true,
        disablePadding: false,
        label: 'Amount',
    },
    {
        id: 'Payin',
        numeric: true,
        disablePadding: false,
        label: 'Payin',
    },
    {
        id: 'Balance',
        numeric: true,
        disablePadding: false,
        label: 'Balance',
    },
    {
        id: 'Link_Invoice',
        numeric: true,
        disablePadding: false,
        label: 'Link Invoice',
    }

];
const Invoices_Statements = ({ datatabal }: any) => {
    const [dense, setDense] = React.useState(false);

    return (
        <>
            <Grid container >
                <Grid item md={12} sm={12} xs={12}>
                    <Paper sx={{ width: '100%', paddingTop: "10px", marginTop: "0px", boxShadow: "0px 2px 14px 2px rgba(174, 174, 174, 0.25)" }}>
                        <TableContainer>
                            <Table
                                className='contenar_tebal_liner'
                                // sx={{ minWidth: 750 }}
                                aria-labelledby="tableTitle"
                                size={dense ? 'small' : 'medium'}
                            >
                                <TableHead>
                                    <TableRow>

                                        {headCells.map((headCell) => (
                                            <TableCell style={headCell.label == "Description" ? { textAlign: "center" } : {}} >
                                                {headCell.label}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>

                                    {datatabal && datatabal.length > 0 ?
                                        datatabal.map((data: any, index: any) => {
                                            // const isItemSelected = isSelected(data.id);
                                            const labelId = `enhanced-table-checkbox-${index}`;
                                            console.log(data, 'datadata', index);
                                            const Tamount: any = data.amount / 100
                                            return (
                                                <TableRow

                                                    // hover
                                                    // onClick={(event) => handleClick(event, row.id)}
                                                    role="checkbox"
                                                    // aria-checked={isItemSelected}
                                                    tabIndex={-1}
                                                    key={index}
                                                // selected={isItemSelected}
                                                // sx={{ cursor: 'pointer' }}
                                                >
                                                    <TableCell padding="checkbox">
                                                        {data.invoice.invoice_no}
                                                    </TableCell>
                                                    <TableCell
                                                    // onClick={() => {
                                                    // }} 
                                                    // style={{ cursor: "pointer" }} 
                                                    >
                                                        {data.customer.name}
                                                    </TableCell>
                                                    <TableCell onClick={() => {
                                                    }}>
                                                        {moment(data.invoice.created_at).format(
                                                            'DD-MMM-YYYY'
                                                        )}

                                                    </TableCell>
                                                    <TableCell onClick={() => {
                                                    }}>
                                                        {moment(data.invoice.updated_at).format(
                                                            'DD-MMM-YYYY'
                                                        )}
                                                    </TableCell>
                                                    <TableCell onClick={() => {
                                                    }}>
                                                        {moment(data.invoice.due_date).format(
                                                            'DD-MMM-YYYY'
                                                        )}
                                                    </TableCell>
                                                    <TableCell onClick={() => {
                                                    }}>
                                                        {data.type=="invoice"?"Invoice":data.type}
                                                    </TableCell>
                                                    {data.type=="invoice"?
                                                    <TableCell onClick={() => {
                                                    }}>
                                                        {/* {data.currancy} */}
                                                        {data.invoice.currency}{" "}{Number(parseFloat(data.amount).toFixed(2)).toLocaleString('en', { minimumFractionDigits: 2 })}
                                                    </TableCell>:
                                                     <TableCell onClick={() => {
                                                    }}>
                                                        
                                                        -
                                                    </TableCell>
                                                    }
                                                    {data.type=="invoice"?
                                                    <TableCell onClick={() => {
                                                    }}>
                                                        {/* {data.currancy} */}
                                                        -
                                                    </TableCell>:
                                                     <TableCell onClick={() => {
                                                    }}>
                                                        {/* {data.currancy} */}
                                                        {data.invoice.currency}{" "}{Number(parseFloat(data.transaction).toFixed(2)).toLocaleString('en', { minimumFractionDigits: 2 })}
                                                    </TableCell>}
                                                    <TableCell style={{ textAlign: "center" }} onClick={() => {
                                                    }}>
                                                        {/* {data.currancy} */}
                                                        {data.invoice.currency}{" "}{Number(parseFloat(data.balance).toFixed(2)).toLocaleString('en', { minimumFractionDigits: 2 })}
                                                    </TableCell>
                                                    <TableCell style={{textAlign:"center"}}>
                                                        {data.linked==1?
                                                        <IconLink />:
                                                        <IconUnlink />}
                                                    </TableCell>

                                                </TableRow>
                                            );
                                        }) : null}
                                    {datatabal?.length === 0 && (
                                        <TableCell colSpan={10}>
                                            <TebalNoData text={'No Statements found'} />
                                        </TableCell>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>
                </Grid>
            </Grid>
        </>
    )
}
export default Invoices_Statements;

const IconLink = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15 8H17C18.333 8 21 8.8 21 12C21 15.2 18.333 16 17 16H15M9 8H7C5.667 8 3 8.8 3 12C3 15.2 5.667 16 7 16H9M8 12H16" stroke="#025041" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
)
const IconUnlink = () => (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15 8H17C18.333 8 21 8.8 21 12C21 15.2 18.333 16 17 16H15M9 8H7C5.667 8 3 8.8 3 12C3 15.2 5.667 16 7 16H9M8 12H16" stroke="#DDDDDD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
)