export const dashboardConstants = {
  GETDASHBOARD_REQUEST: "GETDASHBOARD_REQUEST",
  GETDASHBOARD_SUCCESS: "GETDASHBOARD_SUCCESS",
  GETDASHBOARD_FROM_STORE: "GETDASHBOARD_FROM_STORE",
  GETDASHBOARD_FAILURE: "GETDASHBOARD_FAILURE",

    GET_DASHBOARD_BALANCE_SUCCESS: 'GET_DASHBOARD_BALANCE_SUCCESS',
    GET_DASHBOARD_BILL_SUCCESS: 'GET_DASHBOARD_BILL_SUCCESS',
    
    GET_DASHBOARD_RECENT_REQUEST: 'GET_DASHBOARD_RECENT_REQUEST',
    GET_DASHBOARD_RECENT_SUCCESS: 'GET_DASHBOARD_RECENT_SUCCESS',
    GET_DASHBOARD_RECENT_FAILURE: "GET_DASHBOARD_RECENT_FAILURE",
    
    GET_DASHBOARD_BUDGETDETAILS_REQUEST: 'GET_DASHBOARD_BUDGETDETAILS_REQUEST',
    GET_DASHBOARD_BUDGETDETAILS_SUCCESS: 'GET_DASHBOARD_BUDGETDETAILS_SUCCESS',
    GET_DASHBOARD_BUDGETDETAILS_FAILURE: "GET_DASHBOARD_BUDGETDETAILS_FAILURE",
   
    GET_PAYABLE_BY_ID_REQUEST: 'GET_PAYABLE_BY_ID_REQUEST',
    GET_PAYABLE_BY_ID_SUCCESS: 'GET_PAYABLE_BY_ID_SUCCESS',
    GET_PAYABLE_BY_ID_FAILURE: "GET_PAYABLE_BY_ID_FAILURE",
// invoice
    GET_INVOICE_BY_ID_REQUEST: 'GET_INVOICE_BY_ID_REQUEST',
    GET_INVOICE_BY_ID_SUCCESS: 'GET_INVOICE_BY_ID_SUCCESS',
    GET_INVOICE_BY_ID_FAILURE: "GET_INVOICE_BY_ID_FAILURE",

  GET_DASHBOARD_RECENT_REQUEST: "GET_DASHBOARD_RECENT_REQUEST",
  GET_DASHBOARD_RECENT_SUCCESS: "GET_DASHBOARD_RECENT_SUCCESS",
  GET_DASHBOARD_RECENT_FAILURE: "GET_DASHBOARD_RECENT_FAILURE",
  // updateUserTourCompleted
  UPDATE_USER_TOUR_COMPLETED_REQUEST: "UPDATE_USER_TOUR_COMPLETED_REQUEST",
  UPDATE_USER_TOUR_COMPLETED_SUCCESS: "UPDATE_USER_TOUR_COMPLETED_SUCCESS",
  UPDATE_USER_TOUR_COMPLETED_FAILURE: "UPDATE_USER_TOUR_COMPLETED_FAILURE",

  UPDATE_VIEW_LATEST_FEATURE_REQUEST: "UPDATE_VIEW_LATEST_FEATURE_REQUEST",
  UPDATE_VIEW_LATEST_FEATURE_SUCCESS: "UPDATE_VIEW_LATEST_FEATURE_SUCCESS",
  UPDATE_VIEW_LATEST_FEATURE_FAILURE: "UPDATE_VIEW_LATEST_FEATURE_FAILURE",

  GET_DASHBOARD_BUDGETDETAILS_REQUEST: "GET_DASHBOARD_BUDGETDETAILS_REQUEST",
  GET_DASHBOARD_BUDGETDETAILS_SUCCESS: "GET_DASHBOARD_BUDGETDETAILS_SUCCESS",
  GET_DASHBOARD_BUDGETDETAILS_FAILURE: "GET_DASHBOARD_BUDGETDETAILS_FAILURE",

  GET_PAYABLE_BY_ID_REQUEST: "GET_PAYABLE_BY_ID_REQUEST",
  GET_PAYABLE_BY_ID_SUCCESS: "GET_PAYABLE_BY_ID_SUCCESS",
  GET_PAYABLE_BY_ID_FAILURE: "GET_PAYABLE_BY_ID_FAILURE",

  GET_DASHBOARD_UPDATEDETAILS_REQUEST: "GET_DASHBOARD_UPDATEDETAILS_REQUEST",
  GET_DASHBOARD_UPDATEDETAILS_SUCCESS: "GET_DASHBOARD_UPDATEDETAILS_SUCCESS",
  GET_DASHBOARD_UPDATEDETAILS_FAILURE: "GET_DASHBOARD_UPDATEDETAILS_FAILURE",

  GET_DASHBOARD_CATEGRORY_EXPENSES_REQUEST:
    "GET_DASHBOARD_CATEGRORY_EXPENSES_REQUEST",
  GET_DASHBOARD_CATEGRORY_EXPENSES_SUCCESS:
    "GET_DASHBOARD_CATEGRORY_EXPENSES_SUCCESS",
  GET_DASHBOARD_CATEGRORY_EXPENSES_FAILURE:
    "GET_DASHBOARD_CATEGRORY_EXPENSES_FAILURE",

  GET_DASHBOARD_PAYEE_EXPENSES_REQUEST: "GET_DASHBOARD_PAYEE_EXPENSES_REQUEST",
  GET_DASHBOARD_PAYEE_EXPENSES_SUCCESS: "GET_DASHBOARD_PAYEE_EXPENSES_SUCCESS",
  GET_DASHBOARD_PAYEE_EXPENSES_FAILURE: "GET_DASHBOARD_PAYEE_EXPENSES_FAILURE",

  GET_DASHBOARD_PAYMENT_EXPENSES_REQUEST:
    "GET_DASHBOARD_PAYMENT_EXPENSES_REQUEST",
  GET_DASHBOARD_PAYMENT_EXPENSES_SUCCESS:
    "GET_DASHBOARD_PAYMENT_EXPENSES_SUCCESS",
  GET_DASHBOARD_PAYMENT_EXPENSES_FAILURE:
    "GET_DASHBOARD_PAYMENT_EXPENSES_FAILURE",

  DOCUMENT_VERIFICATION_REQUEST: "DOCUMENT_VERIFICATION_REQUEST",
  DOCUMENT_VERIFICATION_SUCCESS: "DOCUMENT_VERIFICATION_SUCCESS",
  DOCUMENT_VERIFICATION_FAILURE: "DOCUMENT_VERIFICATION_FAILURE",

  GET_CURRENCY_RATE_REQUEST: "GET_CURRENCY_RATE_REQUEST",
  GET_CURRENCY_RATE_SUCCESS: "GET_CURRENCY_RATE_SUCCESS",
  GET_CURRENCY_RATE_FAILURE: "GET_CURRENCY_RATE_FAILURE",

  GET_PAYABLE_TO_APPROVE_REQUEST: "GET_PAYABLE_TO_APPROVE_REQUEST",
  GET_PAYABLE_TO_APPROVE_SUCCESS: "GET_PAYABLE_TO_APPROVE_SUCCESS",
  GET_PAYABLE_TO_APPROVE_FAILURE: "GET_PAYABLE_TO_APPROVE_FAILURE",

  GET_PAYABLE_TO_REJECT_REQUEST: "GET_PAYABLE_TO_REJECT_REQUEST",
  GET_PAYABLE_TO_REJECT_SUCCESS: "GET_PAYABLE_TO_REJECT_SUCCESS",
  GET_PAYABLE_TO_REJECT_FAILURE: "GET_PAYABLE_TO_REJECT_FAILURE",

  GET_PAYABLE_TO_PENDING_REQUEST: "GET_PAYABLE_TO_PENDING_REQUEST",
  GET_PAYABLE_TO_PENDING_SUCCESS: "GET_PAYABLE_TO_PENDING_SUCCESS",
  GET_PAYABLE_TO_PENDING_FAILURE: "GET_PAYABLE_TO_PENDING_FAILURE",

  GET_PAYABLE_TO_UPDATE_REQUEST: "GET_PAYABLE_TO_UPDATE_REQUEST",
  GET_PAYABLE_TO_UPDATE_SUCCESS: "GET_PAYABLE_TO_UPDATE_SUCCESS",
  GET_PAYABLE_TO_UPDATE_FAILURE: "GET_PAYABLE_TO_UPDATE_FAILURE",

  GET_PAYABLE_TO_CANCEL_REQUEST: "GET_PAYABLE_TO_CANCEL_REQUEST",
  GET_PAYABLE_TO_CANCEL_SUCCESS: "GET_PAYABLE_TO_CANCEL_SUCCESS",
  GET_PAYABLE_TO_CANCEL_FAILURE: "GET_PAYABLE_TO_CANCEL_FAILURE",

  GET_PAYABLE_TO_PAY_REQUEST: "GET_PAYABLE_TO_PAY_REQUEST",
  GET_PAYABLE_TO_PAY_SUCCESS: "GET_PAYABLE_TO_PAY_SUCCESS",
  GET_PAYABLE_TO_PAY_FAILURE: "GET_PAYABLE_TO_PAY_FAILURE",

  GET_DASHBOARD_CHART_REQUEST: "GET_DASHBOARD_CHART_REQUEST",
  GET_DASHBOARD_CHART_SUCCESS: "GET_DASHBOARD_CHART_SUCCESS",
  GET_DASHBOARD_CHART_FAILURE: "GET_DASHBOARD_CHART_FAILURE",

  GET_PAYINS_REQUEST: "GET_PAYINS_REQUEST",
  GET_PAYINS_SUCCESS: "GET_PAYINS_SUCCESS",
  GET_PAYINS_FAILURE: "GET_PAYINS_FAILURE",

  GET_PAYMENT_METHOD_PAYINS_REQUEST: "GET_PAYMENT_METHOD_PAYINS_REQUEST",
  GET_PAYMENT_METHOD_PAYINS_SUCCESS: "GET_PAYMENT_METHOD_PAYINS_SUCCESS",
  GET_PAYMENT_METHOD_PAYINS_FAILURE: "GET_PAYMENT_METHOD_PAYINS_FAILURE",

  GET_DASHBOARD_CURRENCY_DATA_SUCCESS: "GET_DASHBOARD_CURRENCY_DATA_SUCCESS",
  GET_DASHBOARD_CURRENCY_DATA_REQUEST: "GET_DASHBOARD_CURRENCY_DATA_REQUEST",
  GET_DASHBOARD_CURRENCY_DATA_FAILURE: "GET_DASHBOARD_CURRENCY_DATA_FAILURE",

  // GET_DASHBOARD_SELECTED_CURRENCY_DATA_SUCCESS:
  //   "GET_DASHBOARD_SELECTED_CURRENCY_DATA_SUCCESS",
  // GET_DASHBOARD_SELECTED_CURRENCY_DATA_REQUEST:
  //   "GET_DASHBOARD_SELECTED_CURRENCY_DATA_REQUEST",
  // GET_DASHBOARD_SELECTED_CURRENCY_DATA_FAILURE:
  //   "GET_DASHBOARD_SELECTED_CURRENCY_DATA_FAILURE",

  GET_BANK_DETAIL_DATA_REQUEST: "GET_BANK_DETAIL_DATA_REQUEST",
  GET_BANK_DETAIL_DATA_SUCCESS: "GET_BANK_DETAIL_DATA_SUCCESS",
  GET_BANK_DETAIL_DATA_FAILURE: "GET_BANK_DETAIL_DATA_FAILURE",

  GET_DASHBOARD_LATESTFEATURES_REQUEST: "GET_DASHBOARD_LATESTFEATURES_REQUEST",
  GET_DASHBOARD_LATESTFEATURES_SUCCESS: "GET_DASHBOARD_LATESTFEATURES_SUCCESS",
  GET_DASHBOARD_LATESTFEATURES_FAILURE: "GET_DASHBOARD_LATESTFEATURES_FAILURE",
};
